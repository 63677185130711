export const perfFlags = {
    DISABLE_SB_ADS: 'DISABLE_SB_ADS',
    DISABLE_DATA_DOG: 'DISABLE_DATA_DOG',
} as const;

type PerfFlags = keyof typeof perfFlags;

const perFlagsQpMap = {
    // disables data dog RUM
    [perfFlags.DISABLE_DATA_DOG]: '__d1',
    // disables all ad units from S/B
    [perfFlags.DISABLE_SB_ADS]: '__d2',
};

export function getPerfQp(flag: PerfFlags): string {
    return perFlagsQpMap[flag];
}
