import get from 'lodash.get';
import Cookies from 'js-cookie';
import { defineMessages } from 'dibs-react-intl';
import { LATEST } from '../SbSharedRefineMenu/sbSharedRefineMenuConstants';
import { PAGE_SIZE_OPTIONS } from '../../constants/sbConstants';
import { COOKIE_PAGE_SIZE } from '../../constants/cookieConstants';

const { label } = defineMessages({
    label: {
        id: 'sb.getPageSizeSelectOptions.label',
        defaultMessage: `{size} per page`,
    },
});

export function getPageSizeSelectOptions(intl) {
    return PAGE_SIZE_OPTIONS.map(option => ({
        value: option.size,
        label: intl.formatMessage(label, { size: option.size }),
    }));
}

export function setSelectedValue(value) {
    return Cookies.set(COOKIE_PAGE_SIZE, value);
}

export function getSelectedValue(pageSize) {
    const selectedOption = PAGE_SIZE_OPTIONS.filter(option => pageSize <= option.size)[0] || {};
    return selectedOption.size;
}

export function isLatestPage(filters = []) {
    // GROWTH-4130 latest param can now take numbers.
    // Should only be a latest page if latest param = 'true'
    const latestFilter = filters.filter(filter => filter.name === LATEST);
    return get(latestFilter, '0.values.0.urlLabel') === 'true';
}
