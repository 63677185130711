import PropTypes from 'prop-types';
import { ComponentLoader } from '../../../global/ComponentLoader/ComponentLoader';

const importFunc = () => {
    return import(
        /* webpackChunkName: "SbRespNotificationUnavailableItemModal" */
        './SbRespNotificationUnavailableItemModal'
    ).then(moduleExport => moduleExport.SbRespNotificationUnavailableItemModal);
};

export function SbRespUnavailableItemLoader(props) {
    return <ComponentLoader load={props.isEnabled} importFunc={importFunc} />;
}

SbRespUnavailableItemLoader.propTypes = {
    isEnabled: PropTypes.bool.isRequired,
};
