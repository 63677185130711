import { QueryRenderer, graphql } from 'react-relay/legacy';
import { CategoryTiles } from 'dibs-buyer-layout/exports/CategoryLinks';
import {
    trackL1ModuleClick,
    trackL2ModuleClick,
    trackCreatorModuleClick,
} from '../../utils/tracking/searchBrowse/nullResultsTracking';
import { useRelayEnvironment } from 'react-relay';

const SHOW_MODULES = true;
const CREATOR_CATEGORY_TYPE = 'tile';

const onL1ModuleClick = (e, { vertical }) => {
    const l1Name = vertical.category;

    if (vertical.type === CREATOR_CATEGORY_TYPE) {
        return trackCreatorModuleClick(l1Name);
    }
    return trackL1ModuleClick(l1Name);
};

const onL2ModuleClick = (e, { vertical, index }) =>
    trackL2ModuleClick(vertical.category, index === 0);

export const SbRespNullSearchResultsTilesLoader = () => {
    const environment = useRelayEnvironment();
    return (
        <QueryRenderer
            environment={environment}
            query={graphql`
                query SbRespNullSearchResultsTilesLoaderQuery($showModules: Boolean!) {
                    viewer {
                        ...CategoryTiles_viewer
                    }
                }
            `}
            variables={{
                showModules: SHOW_MODULES,
            }}
            render={({ error, props: rendererProps }) => {
                const showModules = SHOW_MODULES && !error;
                return (
                    <CategoryTiles
                        withOptimisticModules
                        showModules={showModules}
                        viewer={rendererProps?.viewer || null}
                        onMainTileClick={onL1ModuleClick}
                        onModuleTileClick={onL2ModuleClick}
                    />
                );
            }}
        />
    );
};
