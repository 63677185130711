/**
 * @generated SignedSource<<5943d9de73ad8d805cba6410a6dbd032>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SbRespSearchResultContainer_itemSearch$data = {
  readonly displayUriRef: string | null;
  readonly edges: ReadonlyArray<{
    readonly node: {
      readonly item: {
        readonly isSold: boolean | null;
        readonly serviceId: string | null;
        readonly " $fragmentSpreads": FragmentRefs<"FavoritesProvider_item" | "SharedItemTile_item" | "useSbSharedItemTracking_item">;
      } | null;
    } | null;
  } | null> | null;
  readonly sponsored: {
    readonly items: ReadonlyArray<{
      readonly isSold: boolean | null;
      readonly serviceId: string | null;
      readonly " $fragmentSpreads": FragmentRefs<"FavoritesProvider_item" | "SharedItemTile_item" | "useSbSharedItemTracking_item">;
    } | null> | null;
    readonly metadata: ReadonlyArray<{
      readonly clickTrackerLink: string | null;
      readonly impressionTrackerLink: string | null;
      readonly itemId: string | null;
    } | null> | null;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"SbSharedProductAdWrapper_itemSearch" | "SharedItemTile_itemSearch" | "useMeasurementUnit_itemSearch" | "useSbSharedItemTracking_itemSearch">;
  readonly " $fragmentType": "SbRespSearchResultContainer_itemSearch";
};
export type SbRespSearchResultContainer_itemSearch$key = {
  readonly " $data"?: SbRespSearchResultContainer_itemSearch$data;
  readonly " $fragmentSpreads": FragmentRefs<"SbRespSearchResultContainer_itemSearch">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "serviceId",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "isSold",
    "storageKey": null
  },
  {
    "args": null,
    "kind": "FragmentSpread",
    "name": "FavoritesProvider_item"
  },
  {
    "args": null,
    "kind": "FragmentSpread",
    "name": "useSbSharedItemTracking_item"
  },
  {
    "args": [
      {
        "kind": "Variable",
        "name": "fetchVideo",
        "variableName": "fetchTileVideo"
      },
      {
        "kind": "Variable",
        "name": "isTrade",
        "variableName": "isTrade"
      },
      {
        "kind": "Variable",
        "name": "priceBookName",
        "variableName": "priceBookName"
      },
      {
        "kind": "Variable",
        "name": "showSeller",
        "variableName": "showSeller"
      }
    ],
    "kind": "FragmentSpread",
    "name": "SharedItemTile_item"
  }
];
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "fetchTileVideo"
    },
    {
      "kind": "RootArgument",
      "name": "isTrade"
    },
    {
      "kind": "RootArgument",
      "name": "priceBookName"
    },
    {
      "kind": "RootArgument",
      "name": "showSeller"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "SbRespSearchResultContainer_itemSearch",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayUriRef",
      "storageKey": null
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "useMeasurementUnit_itemSearch",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "SearchBrowseParsedParam",
          "kind": "LinkedField",
          "name": "appliedFilters",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "SearchBrowseParsedParamValue",
              "kind": "LinkedField",
              "name": "values",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "urlLabel",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "args": null,
      "argumentDefinitions": []
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useSbSharedItemTracking_itemSearch"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbSharedProductAdWrapper_itemSearch"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SharedItemTile_itemSearch"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ItemSearchSponsored",
      "kind": "LinkedField",
      "name": "sponsored",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Item",
          "kind": "LinkedField",
          "name": "items",
          "plural": true,
          "selections": (v0/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ItemSearchSponsoredMetadata",
          "kind": "LinkedField",
          "name": "metadata",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "itemId",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "impressionTrackerLink",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "clickTrackerLink",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ItemSearchQueryEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ItemSearchResult",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Item",
              "kind": "LinkedField",
              "name": "item",
              "plural": false,
              "selections": (v0/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ItemSearchQueryConnection",
  "abstractKey": null
};
})();

(node as any).hash = "5d955b7ea5e584747bc5953bf5a78f4f";

export default node;
