/**
 * @generated SignedSource<<a0074bcd59468a2629dec7c36548194b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SbRespRefineMenuSelector_itemSearch$data = {
  readonly filters: ReadonlyArray<{
    readonly " $fragmentSpreads": FragmentRefs<"SbRespRefineMenu_filters">;
  } | null> | null;
  readonly " $fragmentSpreads": FragmentRefs<"SbRespRefineMenu_itemSearch" | "SbSharedSkyscraperAd_itemSearch">;
  readonly " $fragmentType": "SbRespRefineMenuSelector_itemSearch";
};
export type SbRespRefineMenuSelector_itemSearch$key = {
  readonly " $data"?: SbRespRefineMenuSelector_itemSearch$data;
  readonly " $fragmentSpreads": FragmentRefs<"SbRespRefineMenuSelector_itemSearch">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SbRespRefineMenuSelector_itemSearch",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbRespRefineMenu_itemSearch"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbSharedSkyscraperAd_itemSearch"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SearchBrowseFilter",
      "kind": "LinkedField",
      "name": "filters",
      "plural": true,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SbRespRefineMenu_filters"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ItemSearchQueryConnection",
  "abstractKey": null
};

(node as any).hash = "bcff1f9c493774ef98e38edc26eb899b";

export default node;
