import PropTypes from 'prop-types';
import { createFragmentContainer, graphql } from 'react-relay/legacy';
import {
    trackSbViewMoreCtaClick,
    trackRecentItems,
} from 'dibs-recent-history/exports/recentHistoryTracking';
import authModalLoader from 'dibs-buyer-layout/src/utils/AuthModalLoader';
import { trackingConstants, eventNameConstants } from 'dibs-tracking';

import {
    trackRoomModuleClick,
    trackModuleClick,
} from '../../utils/tracking/searchBrowse/nullResultsTracking';
import { useCurrency } from 'dibs-buyer-layout/exports/useCurrency';

// components
import { SbRespNullSearchResultsTilesLoader } from './SbRespNullSearchResultsTilesLoader';
import { CategoryRooms } from 'dibs-buyer-layout/exports/CategoryLinks';
import { SbRespFollowSearchBannerLazy } from '../SbRespFollowSearchBanner/SbRespFollowSearchBannerLazy';
import { SharedRecentlyFavoritedItems } from '../../shared/SharedRecentlyFavoritedItems/SharedRecentlyFavoritedItems';
import { RecentlyViewedItemsLazy } from 'dibs-recent-history/exports/RecentlyViewedItemsLazy';
import SharedYMAL from '../../shared/SharedYMAL/SharedYMAL';
import { SharedFollowTopButton } from '../../shared/SharedFollowTopButton/SharedFollowTopButton';
import { SharedRecentSales } from '../../shared/SharedRecentSales/SharedRecentSales';
import { SbSharedRecommendedItems } from '../SbSharedRecommendedItems/SbSharedRecommendedItems';
import { SbSharedControlledHeadingWrapper } from '../SbSharedControlledHeadingWrapper/SbSharedControlledHeadingWrapper';
import { SbSharedTitle } from '../SbSharedTitle/SbSharedTitle';
import { SbSharedPageBreadcrumbs } from '../SbSharedPageBreadcrumbs/SbSharedPageBreadcrumbs';

import dibsCss from 'dibs-css';
import classnames from 'classnames';

const onRoomModuleClick = (e, { room }) => trackRoomModuleClick(room.type);

const { ECOM_PRODUCT_CLICK, ECOM_PRODUCT_IMPRESSION } = trackingConstants;
const { EVENT_SELECT_ITEM, EVENT_VIEW_ITEM_LIST } = eventNameConstants;

const handleCarouselItemClick = ({ item, index, actionLabel }) => {
    trackModuleClick(actionLabel, index);
    trackRecentItems({
        type: ECOM_PRODUCT_CLICK,
        items: [item],
        startIndex: index + 1, // Tracking needs non zero index position.
        variant: 'null results page',
        list: `null results ${actionLabel} carousel`,
        eventName: EVENT_SELECT_ITEM,
    });
};

const handleCarouselItemImpression = ({ items, index, actionLabel }) => {
    trackRecentItems({
        type: ECOM_PRODUCT_IMPRESSION,
        items: items,
        startIndex: index + 1, // Tracking needs non zero index position.
        variant: 'null results page',
        list: `null results ${actionLabel} carousel`,
        eventName: EVENT_VIEW_ITEM_LIST,
    });
};

export const SbRespNullSearchResultsComponent = props => {
    const { user, viewer, itemSearch, isClient } = props;
    const showNullPageModules = itemSearch?.soldItems?.totalResults;
    const { currency } = useCurrency();

    return (
        <>
            <SbSharedPageBreadcrumbs itemSearch={itemSearch} user={user} />
            <SbSharedControlledHeadingWrapper itemSearch={itemSearch}>
                {Heading => (
                    <>
                        <SbSharedTitle itemSearch={itemSearch} user={user} Heading={Heading}>
                            <SharedFollowTopButton
                                itemSearch={itemSearch}
                                user={user}
                                addLeadingSpace
                            />
                        </SbSharedTitle>
                        {showNullPageModules ? (
                            <div
                                className={classnames(
                                    dibsCss.flex,
                                    dibsCss.flexCol,
                                    dibsCss.wFull,
                                    dibsCss.minW0,
                                    dibsCss.pySmall,
                                    dibsCss.px0
                                )}
                            >
                                <SharedRecentSales
                                    viewer={viewer}
                                    user={user}
                                    itemSearch={itemSearch}
                                    isMobile={false}
                                    isClient={isClient}
                                />
                                <SharedYMAL
                                    user={user}
                                    itemSearch={itemSearch}
                                    isMobile={false}
                                    isClient={isClient}
                                />
                                <SbSharedRecommendedItems viewer={viewer} />
                            </div>
                        ) : (
                            <>
                                {isClient && (
                                    <SbRespFollowSearchBannerLazy
                                        itemSearch={itemSearch}
                                        user={user}
                                    />
                                )}
                                <SbRespNullSearchResultsTilesLoader />
                            </>
                        )}
                        <RecentlyViewedItemsLazy
                            centerTitle
                            wrapperClassName={dibsCss.mbXxlarge}
                            authModalLoader={authModalLoader}
                            trackItemClick={handleCarouselItemClick}
                            trackItemImpression={handleCarouselItemImpression}
                            currency={currency}
                        />
                        {!showNullPageModules && (
                            <>
                                <SharedRecentlyFavoritedItems
                                    onViewMoreCtaClick={trackSbViewMoreCtaClick}
                                    onItemClick={handleCarouselItemClick}
                                    onItemImpression={handleCarouselItemImpression}
                                />
                                <CategoryRooms onClick={onRoomModuleClick} />
                            </>
                        )}
                    </>
                )}
            </SbSharedControlledHeadingWrapper>
        </>
    );
};

SbRespNullSearchResultsComponent.propTypes = {
    user: PropTypes.object,
    viewer: PropTypes.object,
    isClient: PropTypes.bool,
    searchTerm: PropTypes.string,
    relay: PropTypes.object.isRequired,
    itemSearch: PropTypes.object.isRequired,
};

export const SbRespNullSearchResults = createFragmentContainer(SbRespNullSearchResultsComponent, {
    user: graphql`
        fragment SbRespNullSearchResults_user on User {
            ...SbRespFollowSearchBanner_user
            ...SharedYMAL_user
            ...SharedFollowTopButton_user
            ...SbSharedTitle_user
            ...SharedRecentSales_user
            ...SbSharedPageBreadcrumbs_user
        }
    `,
    viewer: graphql`
        fragment SbRespNullSearchResults_viewer on Viewer {
            ...SbSharedRecommendedItems_viewer
            ...SharedRecentSales_viewer
        }
    `,
    itemSearch: graphql`
        fragment SbRespNullSearchResults_itemSearch on ItemSearchQueryConnection {
            ...SbSharedPageBreadcrumbs_itemSearch
            ...SbRespFollowSearchBanner_itemSearch
            ...SharedFollowSearchBanner_itemSearch
            ...SharedYMAL_itemSearch @arguments(priceBookName: $priceBookName)
            ...SharedFollowTopButton_itemSearch
            soldItems: soldResults(first: 28) {
                totalResults
            }
            ...SharedRecentSales_itemSearch
            ...SbSharedTitle_itemSearch
            ...SbSharedControlledHeadingWrapper_itemSearch
        }
    `,
});
