import { FC, ReactElement } from 'react';
import { useFragment, graphql } from 'react-relay';
import HeadingLevel, { HeadingType } from 'dibs-controlled-heading/exports/HeadingLevel';
import { SbSharedControlledHeadingWrapper_itemSearch$key } from './__generated__/SbSharedControlledHeadingWrapper_itemSearch.graphql';

type Props = {
    children: (headingComponent?: HeadingType) => ReactElement;
    itemSearch: SbSharedControlledHeadingWrapper_itemSearch$key | null | undefined;
};
export const SbSharedControlledHeadingWrapper: FC<Props> = ({
    children,
    itemSearch: itemSearchRef,
}) => {
    const itemSearch = useFragment(
        graphql`
            fragment SbSharedControlledHeadingWrapper_itemSearch on ItemSearchQueryConnection {
                meta {
                    subHeader
                }
            }
        `,
        itemSearchRef
    );
    const isSubheaderAvailable = itemSearch?.meta?.subHeader || false;
    if (isSubheaderAvailable) {
        return <HeadingLevel>{Heading => children(Heading)}</HeadingLevel>;
    }

    return children();
};
