/**
 * @generated SignedSource<<376f71a3bf943e82b15ca9882154b226>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SbSharedRelatedItems_itemSearch$data = {
  readonly relatedItems: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly item: {
          readonly serviceId: string | null;
          readonly " $fragmentSpreads": FragmentRefs<"SharedItemTile_item" | "shippingCostHelpers_item" | "useSbSharedItemTracking_item">;
        } | null;
      } | null;
    } | null> | null;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"SharedItemTile_itemSearch" | "useSbSharedItemTracking_itemSearch">;
  readonly " $fragmentType": "SbSharedRelatedItems_itemSearch";
};
export type SbSharedRelatedItems_itemSearch$key = {
  readonly " $data"?: SbSharedRelatedItems_itemSearch$data;
  readonly " $fragmentSpreads": FragmentRefs<"SbSharedRelatedItems_itemSearch">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "isTrade"
    },
    {
      "kind": "RootArgument",
      "name": "priceBookName"
    },
    {
      "kind": "RootArgument",
      "name": "showSeller"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "SbSharedRelatedItems_itemSearch",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SharedItemTile_itemSearch"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "useSbSharedItemTracking_itemSearch"
    },
    {
      "alias": "relatedItems",
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 12
        }
      ],
      "concreteType": "youMayAlsoLikeConnection",
      "kind": "LinkedField",
      "name": "youMayAlsoLike",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "youMayAlsoLikeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ItemSearchResult",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Item",
                  "kind": "LinkedField",
                  "name": "item",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "serviceId",
                      "storageKey": null
                    },
                    {
                      "args": [
                        {
                          "kind": "Variable",
                          "name": "isTrade",
                          "variableName": "isTrade"
                        },
                        {
                          "kind": "Variable",
                          "name": "priceBookName",
                          "variableName": "priceBookName"
                        },
                        {
                          "kind": "Variable",
                          "name": "showSeller",
                          "variableName": "showSeller"
                        }
                      ],
                      "kind": "FragmentSpread",
                      "name": "SharedItemTile_item"
                    },
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "useSbSharedItemTracking_item"
                    },
                    {
                      "kind": "InlineDataFragmentSpread",
                      "name": "shippingCostHelpers_item",
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "vertical",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "ShipmentQuote",
                          "kind": "LinkedField",
                          "name": "shipmentQuotes",
                          "plural": true,
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "isComplimentary",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "isCalculated",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "FinancialAmount",
                              "kind": "LinkedField",
                              "name": "totalAmount",
                              "plural": false,
                              "selections": [
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": "ConvertedAmounts",
                                  "kind": "LinkedField",
                                  "name": "convertedAmounts",
                                  "plural": false,
                                  "selections": [
                                    {
                                      "alias": null,
                                      "args": null,
                                      "kind": "ScalarField",
                                      "name": "USD",
                                      "storageKey": null
                                    }
                                  ],
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "ShipmentServiceMethod",
                              "kind": "LinkedField",
                              "name": "serviceMethod",
                              "plural": false,
                              "selections": [
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": "Region",
                                  "kind": "LinkedField",
                                  "name": "region",
                                  "plural": false,
                                  "selections": [
                                    {
                                      "alias": null,
                                      "args": null,
                                      "kind": "ScalarField",
                                      "name": "regionName",
                                      "storageKey": null
                                    }
                                  ],
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "args": null,
                      "argumentDefinitions": []
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "youMayAlsoLike(first:12)"
    }
  ],
  "type": "ItemSearchQueryConnection",
  "abstractKey": null
};

(node as any).hash = "d3bbcdb9f7466f64b1f88edecd7c208a";

export default node;
