import { FC } from 'react';
import { graphql, useFragment } from 'react-relay';
import classnames from 'classnames';
import HeadingLevel from 'dibs-controlled-heading/exports/HeadingLevel';
import { Link } from 'dibs-elements/exports/Link';
import { ItemType } from 'dibs-recent-history/exports/types';
import { FormattedMessage } from 'dibs-react-intl';

import { SharedCarousel } from '../SharedCarousel/SharedCarousel';
import { SharedItemsSwiper } from '../SharedItemsSwiper/SharedItemsSwiper';
import { CarouselType } from '../SharedCarousel/SharedCarousel';
import { useSbSelector } from '../../reducers/useSbSelector';
import { useServerVarsContext } from '../../global/ServerVarsContext/ServerVarsContext';
import {
    VERTICAL_OFFSET_RESP_IMG,
    HORIZONTAL_OFFSET_RESP_IMG,
} from '../../constants/imageConstants';

import styles from './SharedCarouselWrapper.scss';

import { SharedCarouselWrapper_item$key } from './__generated__/SharedCarouselWrapper_item.graphql';

export type ItemClick = { index: number; item: ItemType; actionLabel: string };
export type ItemImpression = { index: number; items: [ItemType]; actionLabel: string };

export type ClassNames = {
    wrapper?: string;
    arrow?: string;
    placeholder?: string;
    itemImage?: string;
    title?: string;
    itemLink?: string;
    list?: string;
};

type Props = {
    classNames?: ClassNames;
    showFullHeader?: boolean;
    itemsPerPage?: number;
    items: SharedCarouselWrapper_item$key;
    viewMoreHref: string;
    title: string;
    titleId: string;
    carouselType: CarouselType;
    actionLabel: string;
    onViewMoreCtaClick?: (actionLabel: string) => void;
    onItemClick?: ({ item, index, actionLabel }: ItemClick) => void;
    onItemImpression?: ({ items, index, actionLabel }: ItemImpression) => void;
};

const itemFragment = graphql`
    fragment SharedCarouselWrapper_item on Item @relay(plural: true) {
        title
        serviceId
        browseUrl
        firstPhotoWebPath(size: large)
        categoryCode
        contemporaryTrackingString
        categoryPath
        localizedPdpUrl
        seller {
            serviceId
        }
    }
`;

const SWIPER_ITEMS_PER_PAGE = 4.5;

export const SharedCarouselWrapper: FC<Props> = ({
    classNames = {},
    showFullHeader = true,
    itemsPerPage = 6,
    items: itemsRef,
    viewMoreHref,
    title,
    titleId,
    carouselType,
    actionLabel,
    onViewMoreCtaClick = () => {},
    onItemClick,
    onItemImpression,
}) => {
    const { isTablet } = useServerVarsContext();
    const afterDisplay = useSbSelector(store => store.relayVariables.variables.afterDisplay);
    const items = useFragment(itemFragment, itemsRef);

    if (!afterDisplay || !items?.length) {
        return null;
    }
    const itemList = items.map(item => ({
        category: item?.categoryPath || '',
        contemporaryTrackingString: item?.contemporaryTrackingString || '',
        dealerPk: item?.seller?.serviceId || '',
        itemBrowseUrl: item?.browseUrl || '',
        itemCategoryCode: item?.categoryCode || '',
        itemId: item?.serviceId || '',
        itemImageUrl: item?.firstPhotoWebPath || '',
        itemPdpUrl: item?.localizedPdpUrl || '',
        itemTitle: item?.title || '',
    }));

    const itemClasses = classnames(styles.item, { [styles.center]: itemList.length < 3 });
    const maxCarouselItemLength = (isTablet ? SWIPER_ITEMS_PER_PAGE : itemsPerPage) * 2;
    const showViewMoreCta = itemList.length >= maxCarouselItemLength;
    const titleClassName = classNames?.title || styles?.title;

    return (
        <>
            <div className={styles.headerContainer}>
                <div className={titleClassName}>
                    <HeadingLevel>
                        {Heading => (
                            <Heading id={titleId} className={styles.title}>
                                {title}
                            </Heading>
                        )}
                    </HeadingLevel>
                </div>
                {showFullHeader && showViewMoreCta && (
                    <Link
                        className={styles.viewMoreLink}
                        href={viewMoreHref}
                        onClick={() => onViewMoreCtaClick(actionLabel)}
                    >
                        <FormattedMessage id="shared.viewMoreCta" defaultMessage="View More" />
                    </Link>
                )}
            </div>
            {isTablet ? (
                <SharedItemsSwiper
                    items={itemList}
                    itemsPerPage={SWIPER_ITEMS_PER_PAGE}
                    offsetVertical={VERTICAL_OFFSET_RESP_IMG}
                    offsetHorizontal={HORIZONTAL_OFFSET_RESP_IMG}
                    classNames={{
                        wrapper: classNames?.wrapper || styles.swiperWrapper,
                        item: itemClasses,
                        list: itemList.length > 5 ? '' : styles.list,
                        placeholder: classNames?.placeholder || styles.placeholder,
                        itemImage: classNames?.itemImage || styles.itemImage,
                    }}
                    onItemClick={onItemClick}
                    onItemImpression={onItemImpression}
                    actionLabel={actionLabel}
                />
            ) : (
                <SharedCarousel
                    items={itemList}
                    itemsPerPage={itemsPerPage}
                    classNames={{
                        list: itemList.length > itemsPerPage ? '' : styles.list,
                        placeholder: styles.placeholder,
                        itemLink: styles.itemLink,
                        itemImage: styles.itemImage,
                        arrow: classNames?.arrow,
                        wrapper: classNames?.wrapper || styles.carouselWrapper,
                    }}
                    carouselType={carouselType}
                    onItemClick={onItemClick}
                    onItemImpression={onItemImpression}
                    actionLabel={actionLabel}
                />
            )}
        </>
    );
};
