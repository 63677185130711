import { FC } from 'react';

import { FormattedMessage } from 'dibs-react-intl';
import { Link } from 'dibs-elements/exports/Link';

import styles from './SbRespAppliedFiltersClearAll.scss';

type Props = {
    onClearAllClick: () => void;
};

export const SbRespAppliedFiltersClearAll: FC<Props> = ({ onClearAllClick }) => (
    <Link
        buyerLinkType="standardLink"
        className={styles.clearAllButton}
        dataTn="applied-filters-clear-all"
        onClick={onClearAllClick}
    >
        <FormattedMessage id="sb.SbRespAppliedFiltersClearAll.button" defaultMessage="Clear All" />
    </Link>
);
