import { Component } from 'react';
import PropTypes from 'prop-types';
import { LazyImageWrapper } from '../../components/global/LazyImageWrapper';
import { TrackingSwiper } from '../../components/global/TrackingSwiper';
import { trackInitialDisplay } from 'dibs-recent-history/exports/recentHistoryTracking';
import { SRC_SET_SIZES_RESP_CAROUSEL_TILE } from '../../constants/imageConstants';
import { getSrcSetString } from 'dibs-search-product-tile/exports/srcSetHelpers';
import { Link } from 'dibs-elements/exports/Link';

export class SharedItemsSwiper extends Component {
    constructor() {
        super();

        this.handleInitialDisplay = this.handleInitialDisplay.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);
    }

    handleInitialDisplay() {
        const { items, actionLabel } = this.props;
        const itemsReturned = items.length;
        trackInitialDisplay({ itemsReturned, actionLabel });
    }

    handlePageChange() {
        /*
         * Needed for lazy images to load on swipe event.
         */
        this.forceUpdate();
    }

    getCarouselItems() {
        const { items, classNames, offsetVertical, offsetHorizontal, onItemClick, actionLabel } =
            this.props;

        return items.map((item, index) => {
            const itemImageUrlSrc = item.itemImageUrl?.src || item.itemImageUrl;
            const srcSetAttributes = itemImageUrlSrc
                ? {
                      srcSet: getSrcSetString(itemImageUrlSrc),
                      sizes: SRC_SET_SIZES_RESP_CAROUSEL_TILE,
                  }
                : {};

            return (
                <Link
                    key={`recent-${item.itemId}`}
                    href={item.itemPdpUrl}
                    onClick={() => onItemClick({ item, index, actionLabel })}
                >
                    <LazyImageWrapper
                        offsetHorizontal={offsetHorizontal}
                        offsetVertical={offsetVertical}
                        item={null}
                    >
                        <img
                            className={classNames.itemImage}
                            src={itemImageUrlSrc}
                            alt={item.itemTitle}
                            {...srcSetAttributes}
                        />
                    </LazyImageWrapper>
                </Link>
            );
        });
    }

    render() {
        const { items, itemsPerPage, onItemImpression, actionLabel } = this.props;

        return (
            <TrackingSwiper
                items={items}
                itemsPerPage={items.length < itemsPerPage ? items.length : itemsPerPage}
                classNames={this.props.classNames}
                onPageChange={this.handlePageChange}
                onItemImpression={onItemImpression}
                onInitialDisplay={this.handleInitialDisplay}
                actionLabel={actionLabel}
            >
                {this.getCarouselItems()}
            </TrackingSwiper>
        );
    }
}

SharedItemsSwiper.propTypes = {
    items: PropTypes.arrayOf(
        PropTypes.shape({
            contemporaryTrackingString: PropTypes.string,
            itemTitle: PropTypes.string.isRequired,
            itemId: PropTypes.string.isRequired,
            itemBrowseUrl: PropTypes.string,
            itemCategoryCode: PropTypes.string.isRequired,
            itemImageUrl: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
            itemPdpUrl: PropTypes.string.isRequired,
            dealerPk: PropTypes.string.isRequired,
            category: PropTypes.string.isRequired,
        })
    ).isRequired,
    itemsPerPage: PropTypes.number.isRequired,
    classNames: PropTypes.shape({
        wrapper: PropTypes.string.isRequired,
        item: PropTypes.string.isRequired,
        placeholder: PropTypes.string.isRequired,
        itemImage: PropTypes.string.isRequired,
    }).isRequired,
    offsetVertical: PropTypes.number,
    offsetHorizontal: PropTypes.number,
    actionLabel: PropTypes.string,
    onItemImpression: PropTypes.func,
    onItemClick: PropTypes.func,
};
