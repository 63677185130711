import { FC, Suspense, useEffect } from 'react';
import { PreloadedQuery, graphql, usePreloadedQuery, useQueryLoader } from 'react-relay';

import {
    SharedCarouselWrapper,
    ClassNames,
    ItemClick,
    ItemImpression,
} from '../SharedCarouselWrapper/SharedCarouselWrapper';
import { useSbSelector } from '../../reducers/useSbSelector';

import { SharedRecentlyFavoritedItemsQuery } from './__generated__/SharedRecentlyFavoritedItemsQuery.graphql';
import { LoadingSpinner } from '../../finding/SbSharedTradeBenefitPlacement/LoadingSpinner';
import { filterFalsy } from 'dibs-ts-utils/exports/filterFalsy';

const sharedRecentlyFavoritedItemsQuery = graphql`
    query SharedRecentlyFavoritedItemsQuery($userId: String = "") {
        viewer {
            portfolios(first: 1, portfolioTypes: HEART, userIds: [$userId]) {
                edges {
                    node {
                        items(first: 12) {
                            edges {
                                node {
                                    item {
                                        ...SharedCarouselWrapper_item
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

type Props = {
    classNames?: ClassNames;
    showFullHeader?: boolean;
    itemsPerPage?: number;
    onViewMoreCtaClick?: (actionLabel: string) => void;
    onItemClick?: ({ item, index, actionLabel }: ItemClick) => void;
    onItemImpression?: ({ items, index, actionLabel }: ItemImpression) => void;
};

const VIEW_MORE_URL = '/favorites/favorited/';
const TITLE = 'Recently Saved';
const TITLE_ID = 'shared.recentlySaved';
const CAROUSEL_TYPE = 'saved';
const ACTION_LABEL = 'recently saved';

const RecentlyFavoritedItems: FC<
    Props & { queryReference: PreloadedQuery<SharedRecentlyFavoritedItemsQuery> }
> = ({
    queryReference,
    classNames,
    showFullHeader,
    itemsPerPage,
    onViewMoreCtaClick,
    onItemClick,
    onItemImpression,
}) => {
    const { viewer } = usePreloadedQuery<SharedRecentlyFavoritedItemsQuery>(
        sharedRecentlyFavoritedItemsQuery,
        queryReference
    );

    const items = (viewer?.portfolios?.edges || [])
        .flatMap(portfolio => portfolio?.node?.items?.edges?.map(item => item?.node?.item) || null)
        .filter(filterFalsy);

    return (
        <SharedCarouselWrapper
            classNames={classNames}
            showFullHeader={showFullHeader}
            itemsPerPage={itemsPerPage}
            items={items}
            onViewMoreCtaClick={onViewMoreCtaClick}
            onItemClick={onItemClick}
            onItemImpression={onItemImpression}
            viewMoreHref={VIEW_MORE_URL}
            title={TITLE}
            titleId={TITLE_ID}
            carouselType={CAROUSEL_TYPE}
            actionLabel={ACTION_LABEL}
        />
    );
};

export const SharedRecentlyFavoritedItems: FC<Props> = ({
    classNames,
    showFullHeader,
    itemsPerPage,
    onViewMoreCtaClick,
    onItemClick,
    onItemImpression,
}) => {
    const [queryReference, loadQuery] = useQueryLoader<SharedRecentlyFavoritedItemsQuery>(
        sharedRecentlyFavoritedItemsQuery
    );
    const userId = useSbSelector(store => store.relayVariables.variables.userId || '');

    useEffect(() => {
        if (userId) {
            loadQuery({ userId });
        }
    }, [loadQuery, userId]);

    if (!userId) {
        return null;
    }

    if (!queryReference) {
        return <LoadingSpinner />;
    }

    return (
        <Suspense fallback={<LoadingSpinner />}>
            <RecentlyFavoritedItems
                queryReference={queryReference}
                classNames={classNames}
                showFullHeader={showFullHeader}
                itemsPerPage={itemsPerPage}
                onViewMoreCtaClick={onViewMoreCtaClick}
                onItemClick={onItemClick}
                onItemImpression={onItemImpression}
            />
        </Suspense>
    );
};
