import { createFragmentContainer, graphql } from 'react-relay/legacy';
import PropTypes from 'prop-types';

// Components
import LazyImage from 'dibs-react-lazy-image';

// Helpers
import classnames from 'classnames';
import get from 'lodash.get';

// Styles
import styles from './LazyImageWrapper.scss';

export const getSvgPlaceholderDimensions = svgPlaceholder => {
    const matches = svgPlaceholder.match(/viewBox=\'\d+ \d+ (\d+ \d+)\'/);
    if (matches) {
        const dimensions = matches[1].split(' ');
        const width = dimensions[0];
        const height = dimensions[1];
        return { height, width };
    }
    return null;
};

export const LazyImageWrapperComponent = ({ item, children, offsetHorizontal, offsetVertical }) => {
    const svgPlaceholder = get(item, 'photos[0].placeholder');
    const svgPlaceholderDimensions = svgPlaceholder
        ? getSvgPlaceholderDimensions(svgPlaceholder)
        : null;
    const showSvgPlaceholder = !!svgPlaceholderDimensions;
    const isPlaceholderNarrow = showSvgPlaceholder
        ? Number(svgPlaceholderDimensions.height) > Number(svgPlaceholderDimensions.width)
        : false;
    const placeholderClass = classnames(styles.placeholder, {
        [styles.additionalBlur]: showSvgPlaceholder,
        [styles.isNarrow]: isPlaceholderNarrow,
    });

    return (
        <LazyImage
            offsetHorizontal={offsetHorizontal}
            offsetVertical={offsetVertical}
            placeholderClass={placeholderClass}
            placeholder={showSvgPlaceholder ? svgPlaceholder : null}
        >
            {children}
        </LazyImage>
    );
};

LazyImageWrapperComponent.propTypes = {
    item: PropTypes.object,
    children: PropTypes.node.isRequired,
    offsetHorizontal: PropTypes.number.isRequired,
    offsetVertical: PropTypes.number.isRequired,
};

export const LazyImageWrapper = createFragmentContainer(LazyImageWrapperComponent, {
    item: graphql`
        fragment LazyImageWrapper_item on Item {
            photos(limit: 1) {
                placeholder
            }
        }
    `,
});
