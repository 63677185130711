import { FC, ReactNode } from 'react';
import { useFragment, graphql } from 'react-relay';

import { useServerVarsContext } from '../../global/ServerVarsContext/ServerVarsContext';
import { SbRespStickyMenu } from '../SbRespStickyMenu/SbRespStickyMenu';
import { SbRespRefineMenu } from '../SbRespRefineMenu/SbRespRefineMenu';
import { SbSharedSkyscraperAd } from '../SbSharedSkyscraperAd/SbSharedSkyscraperAd';

import styles from './SbRespRefineMenuSelector.scss';

import { SbRespRefineMenuSelector_itemSearch$key } from './__generated__/SbRespRefineMenuSelector_itemSearch.graphql';
import { SbRespRefineMenuSelector_viewer$key } from './__generated__/SbRespRefineMenuSelector_viewer.graphql';
import { SbRespRefineMenuSelector_user$key } from './__generated__/SbRespRefineMenuSelector_user.graphql';

const TabletWrapper: FC<{ children: ReactNode }> = ({ children }) => (
    <div className={styles.refineMenuContainer}>{children}</div>
);

const viewerFragment = graphql`
    fragment SbRespRefineMenuSelector_viewer on Viewer {
        ...SbRespRefineMenu_viewer
    }
`;

const userFragment = graphql`
    fragment SbRespRefineMenuSelector_user on User {
        ...SbRespRefineMenu_user
    }
`;

const itemSearchFragment = graphql`
    fragment SbRespRefineMenuSelector_itemSearch on ItemSearchQueryConnection {
        ...SbRespRefineMenu_itemSearch
        ...SbSharedSkyscraperAd_itemSearch
        filters {
            ...SbRespRefineMenu_filters
        }
    }
`;

type Props = {
    currency: string;
    itemSearch: SbRespRefineMenuSelector_itemSearch$key;
    viewer: SbRespRefineMenuSelector_viewer$key;
    user: SbRespRefineMenuSelector_user$key;
    isClient?: boolean;
    uriRef?: string;
    isThinPage: boolean;
};

export const SbRespRefineMenuSelector: FC<Props> = ({
    currency,
    itemSearch: itemSearchRef,
    viewer: viewerRef,
    user: userRef,
    isClient,
    uriRef,
    isThinPage,
}) => {
    const viewer = useFragment(viewerFragment, viewerRef);
    const user = useFragment(userFragment, userRef);
    const itemSearch = useFragment(itemSearchFragment, itemSearchRef);

    const { isTablet } = useServerVarsContext();
    const { filters } = itemSearch || {};
    const sharedProps = {
        currency,
        itemSearch,
        viewer,
        user,
        isClient,
    };

    const Wrapper = isTablet ? TabletWrapper : SbRespStickyMenu;

    return (
        <Wrapper>
            {/* Refine Menu */}
            {/* @ts-ignore Type 'null' is not assignable to type '_FragmentRefs<string>'. Don't want to filterFalsy due to TS and PropTypes fight */}
            <SbRespRefineMenu {...sharedProps} filters={filters || []} uriRef={uriRef} />

            {/* Ad */}
            {!isThinPage && <SbSharedSkyscraperAd itemSearch={itemSearch} />}
        </Wrapper>
    );
};
