const BROWSE_CATEGORIES_REGEX =
    '/:vertical(furniture|fashion|jewelry|art)/:categories([\\s\\S]*)*?';

// .com Routing for reference : https://github.com/1stdibs/1stdibs.com/blob/master/dibs/application/config/routes.php
export const searchBrowse = [
    // more from seller
    { path: '/more-from-seller/:param([\\s\\S]*)?' },
    // fashion-jewelry
    { path: '/fashion-jewelry/:param([\\s\\S]*)?' },
    // design-your-own
    { path: '/design-your-own/:param([\\s\\S]*)?' },
    // multi-purpose route for collections, dealers, ... sale.
    {
        path: '/:segment(dealers|locations|creators|associations|sale|new-arrivals|collections)/:param([\\s\\S]*)?',
    },
    // /contemporary/*
    { path: '/contemporary/:param([\\s\\S]*)?' },
    // /dealers/{url-label}/ and /dealers/{url-label}/shop/...params
    { path: '/dealers/:param([\\s\\S]*)/shop/:param([\\s\\S]*)?' },
    // recognized dealers
    { path: '/recognized-dealers/:param([\\s\\S]*)?' },
    // gallery
    { path: '/gallery/:param([\\s\\S]*)?' },
    // fair warning
    { path: '/fair-warning/:param([\\s\\S]*)?' },
    // browse categories
    { path: BROWSE_CATEGORIES_REGEX },
    // search page
    { path: '/search/:param([\\s\\S]*)?' },
    // view page
    { path: '/view/:param([\\s\\S]*)?' },
];

export const searchBrowseCategoryRoutes = [{ path: '' }, { path: BROWSE_CATEGORIES_REGEX }];
