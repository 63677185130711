import { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import SV from 'server-vars';
import { getPublisherTagInstance } from '../../../utils/getPublisherTagInstance';
import { gptUrl } from '../../../constants/ads';
import VisibilityTracker from 'dibs-visibility-tracker/exports/VisibilityTracker';

export class AdUnit extends Component {
    constructor(props) {
        super(props);

        this.loadPromise = null;
        this.state = {
            hasMounted: false,
            gptLoaded: false,
            adSlotRef: null,
        };

        this.ref = createRef();
        this.loadGPT = this.loadGPT.bind(this);
        this.defineSlot = this.defineSlot.bind(this);
        this.onVisibilityChange = this.onVisibilityChange.bind(this);
        this.triggerGPTLoad = this.triggerGPTLoad.bind(this);
    }

    componentDidMount() {
        if (SV.get('isSbAdsEnabled')) {
            this.setState({ hasMounted: true }, () => {
                if (!this.props.lazyLoad) {
                    this.triggerGPTLoad();
                }
            });
        }
    }

    componentDidUpdate() {
        const { hasMounted, gptLoaded, adSlotRef } = this.state;
        if (hasMounted && gptLoaded && !adSlotRef) {
            this.defineSlot();
            this.displayAd();
        }
    }

    componentWillUnmount() {
        const { adSlotRef } = this.state;
        // We need to destroy slot to free it up for future slot uses with same adId, [width, height], and elementId
        if (adSlotRef) {
            const gt = getPublisherTagInstance();
            gt.destroySlots([adSlotRef]);
        }
    }

    triggerGPTLoad() {
        this.loadGPT().then(() => this.setState({ gptLoaded: true }));
    }

    onVisibilityChange({ disconnect, isVisible }) {
        if (isVisible) {
            this.triggerGPTLoad();
            disconnect();
        }
    }

    loadGPT() {
        if (!this.state.hasMounted) {
            return Promise.resolve();
        }
        return (
            // Stole this logic from nfl/react-gpt
            // https://github.com/nfl/react-gpt/blob/9d05a2b551511c14a76fb6257a8c710b6fbe1700/src/createManager.js#L466
            this.loadPromise ||
            (this.loadPromise = new Promise((resolve, reject) => {
                if (!window) {
                    reject(new Error('DOM not available'));
                    return;
                }
                const onLoad = () => {
                    if (window.googletag) {
                        // make sure API is ready for use.
                        window.googletag.cmd.push(() => {
                            resolve();
                        });
                    } else {
                        reject(new Error('window.googletag is not available'));
                    }
                };
                if (window.googletag && window.googletag.apiReady) {
                    onLoad();
                } else {
                    const script = document.createElement('script');
                    script.async = true;
                    script.onload = onLoad;
                    script.onerror = () => {
                        reject(new Error('failed to load script'));
                    };
                    script.src = gptUrl;
                    document.head.appendChild(script);
                }
            }))
        );
    }

    displayAd() {
        const gt = getPublisherTagInstance();
        const { elementId } = this.props;

        gt.cmd.push(() => {
            gt.display(elementId);
        });
    }

    defineSlot() {
        const gt = getPublisherTagInstance();
        const { adId, width, height, elementId, hasResponsiveHeightWidth } = this.props;

        if (hasResponsiveHeightWidth) {
            gt.pubads().addEventListener('slotRenderEnded', event =>
                this.props.onSlotRenderEnded(event, this.ref.current)
            );
        }
        gt.cmd.push(() => {
            gt.pubads();

            // define ad slot
            const adSlotRef = gt.defineSlot(adId, [width, height], elementId);
            if (adSlotRef) {
                // configure ad slot
                adSlotRef.addService(gt.pubads());
            }

            gt.pubads().enableSingleRequest();
            gt.enableServices();

            this.setState({ adSlotRef });
        });
    }

    render() {
        const { elementId, width, height, className, lazyLoad } = this.props;

        return (
            <>
                {!!lazyLoad && (
                    <VisibilityTracker
                        onVisibilityChange={this.onVisibilityChange}
                        elementRef={this.ref}
                        observerOptions={{
                            rootMargin: `100% 0px 100% 0px`, // Render slots within 1 viewport.
                        }}
                    />
                )}
                <div
                    className={className}
                    id={elementId}
                    data-target-width={width}
                    data-target-height={height}
                    ref={this.ref}
                />
            </>
        );
    }
}

AdUnit.defaultProps = {
    hasResponsiveHeightWidth: false,
    onSlotRenderEnded: () => {},
};

AdUnit.propTypes = {
    adId: PropTypes.string,
    elementId: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
    className: PropTypes.string,
    hasResponsiveHeightWidth: PropTypes.bool,
    onSlotRenderEnded: PropTypes.func.isRequired,
    lazyLoad: PropTypes.bool,
};
