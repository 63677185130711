import { UNAVAILABLE_ITEM_NOTIFICATION_KEY } from '../sbRespNotificationConstants';

// components
import { SharedNotificationsWrapper } from '../../../shared/SharedNotificationsWrapper';
import { SbRespUnavailableItemLoader } from './SbRespNotificationUnavailableItemLoader';

export const SbRespNotificationUnavailableItem = () => (
    <SharedNotificationsWrapper type={UNAVAILABLE_ITEM_NOTIFICATION_KEY}>
        {({ isEnabled }) => <SbRespUnavailableItemLoader isEnabled={isEnabled} />}
    </SharedNotificationsWrapper>
);
