/**
 * @generated SignedSource<<142803973b86ece4e145fb6b4f78c2b0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SbRespRefineMenu_filters$data = ReadonlyArray<{
  readonly " $fragmentSpreads": FragmentRefs<"SbSharedRefineMenuDisplayPrefsSelect_filters" | "SbSharedRefineMenuFilters_filters">;
  readonly " $fragmentType": "SbRespRefineMenu_filters";
}>;
export type SbRespRefineMenu_filters$key = ReadonlyArray<{
  readonly " $data"?: SbRespRefineMenu_filters$data;
  readonly " $fragmentSpreads": FragmentRefs<"SbRespRefineMenu_filters">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "SbRespRefineMenu_filters",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbSharedRefineMenuFilters_filters"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbSharedRefineMenuDisplayPrefsSelect_filters"
    }
  ],
  "type": "SearchBrowseFilter",
  "abstractKey": null
};

(node as any).hash = "fbe4a3afa9de14b2bf5167e3b6714133";

export default node;
