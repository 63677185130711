import PropTypes from 'prop-types';
import { AdUnit } from './AdUnit';
import styles from './ads.scss';

const onSlotRenderEnded = (event, ref, elementId) => {
    const iframe = ref.querySelector('iframe');
    if (event.slot.getSlotElementId() === elementId && iframe) {
        const innerDoc = iframe.contentDocument || iframe.contentWindow.document;
        const body = innerDoc.querySelector('body');
        const adImg = innerDoc.querySelector('img');
        const googleImgDiv = innerDoc.querySelector('#google_image_div');
        googleImgDiv.setAttribute('style', 'display: flex;');
        body.setAttribute('style', 'display: flex; align-items: center; height: 100% !important;');
        innerDoc.querySelector('html').setAttribute('style', 'height: 100%;');
        iframe.setAttribute('width', '100%');
        iframe.setAttribute('height', '100%');
        adImg.setAttribute('width', '100%');
        adImg.setAttribute('height', '100%');
        adImg.setAttribute('style', 'height: 100%; width: 100%; position: static;');
    }
};

const ProductTileAd = ({ adId, elementId, width = 331, height = 419, lazyLoad = false }) => {
    return (
        <div className={styles.tileContainer}>
            <div className={styles.tileInner} data-tn="product-tile-ad">
                <AdUnit
                    adId={adId}
                    elementId={elementId}
                    width={width}
                    height={height}
                    className={styles.tilePlacement}
                    lazyLoad={lazyLoad}
                    hasResponsiveHeightWidth
                    onSlotRenderEnded={(event, ref) =>
                        ref && onSlotRenderEnded(event, ref, elementId)
                    }
                />
            </div>
        </div>
    );
};

ProductTileAd.propTypes = {
    adId: PropTypes.string,
    elementId: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
    lazyLoad: PropTypes.bool,
};

export { ProductTileAd };
