import { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { AdUnit } from './AdUnit';
import styles from './ads.scss';

const SkyscraperAd = ({ adId, elementId, width = 320, height = 82 }) => {
    const [hasAd, setHasAd] = useState(true);
    const onSlotRenderEnded = (event, ref, elId) => {
        if (event.slot.getSlotElementId() === elId) {
            const iframe = ref.querySelector('iframe');

            if (!iframe && hasAd) {
                document
                    .querySelector(`#${elId} div`)
                    .setAttribute('style', 'height: 0; width: 0;');
                setHasAd(false);
            }
        }
    };

    const skyScraperClasses = classnames(styles.skyScraper, { [styles.hasAd]: hasAd });

    return (
        <div className={skyScraperClasses} data-tn="skyscraper-ad">
            <AdUnit
                adId={adId}
                elementId={elementId}
                width={width}
                height={height}
                hasResponsiveHeightWidth
                onSlotRenderEnded={(event, ref) => ref && onSlotRenderEnded(event, ref, elementId)}
            />
        </div>
    );
};

SkyscraperAd.propTypes = {
    adId: PropTypes.string,
    elementId: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
};

export { SkyscraperAd };
