import { Component } from 'react';
import PropTypes from 'prop-types';
import { Swiper } from 'dibs-pinch-zoom-swipe';
import { SharedCarouselTracking } from './SharedCarouselTracking';

class TrackingSwiper extends Component {
    constructor() {
        super();

        this.handlePageChange = this.handlePageChange.bind(this);
    }

    handlePageChange(page) {
        this.props.onPageChange(page);
    }

    render() {
        const {
            items,
            itemsPerPage,
            classNames,
            children,
            onInitialDisplay,
            onItemImpression,
            actionLabel,
        } = this.props;

        return (
            <SharedCarouselTracking
                items={items}
                itemsPerPage={itemsPerPage}
                onInitialDisplay={onInitialDisplay}
                onItemImpression={onItemImpression}
                onPageChange={this.handlePageChange}
                actionLabel={actionLabel}
            >
                {({ handlePageChange }) => (
                    <Swiper
                        itemsPerPage={itemsPerPage}
                        onPageChange={handlePageChange}
                        classNames={classNames}
                    >
                        {children}
                    </Swiper>
                )}
            </SharedCarouselTracking>
        );
    }
}

TrackingSwiper.propTypes = {
    items: PropTypes.array.isRequired,
    itemsPerPage: PropTypes.number.isRequired,
    children: PropTypes.array.isRequired,
    classNames: PropTypes.object.isRequired,
    onPageChange: PropTypes.func,
    onItemImpression: PropTypes.func.isRequired,
    onInitialDisplay: PropTypes.func,
    actionLabel: PropTypes.string,
};

TrackingSwiper.defaultProps = {
    onPageChange: () => {},
    onInitialDisplay: () => {},
};

export { TrackingSwiper };
