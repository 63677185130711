import { Component } from 'react';
import PropTypes from 'prop-types';
import { createFragmentContainer, graphql } from 'react-relay/legacy';
import { connect } from 'react-redux';
import { updateRelayVariables } from '../../actions/sharedRelayVariableActions';
import { FindingRouter } from '../../router/FindingRouter';
import { LoadingComponent } from '../../components/global/LoadingComponent';
import { searchBrowse as routes } from '../../router/routes/searchBrowse';
import { stripRoutingSegment } from 'dibs-buyer-layout/exports/stripRoutingSegment';
import { addCdnQueryParams, getNewPaginationUriRef } from '../../utils/uriUtils';
import { toggleRequestInFlight, updateUriRef, updateZipCode } from '../../actions/filterActions';
import { getFollowTypeArrayFromUri } from '../../utils/favoritesUtils';
import {
    hasPersonalizedFilterQueryParam,
    getPersonalizedFilterValues,
    getUrlWithoutPersonalizedFilterParam,
    getPersonalizedFilterAction,
    shouldApplyPersonalizedFilter,
    getPersonalizedFilterUriRef,
} from '../../shared/helpers/personalizedFiltersHelpers';
import {
    getUserZipCode,
    getUserSessionCountryCode,
    getUserSessionRegions,
} from 'dibs-regional-info/exports/regionalInfoHelpers';
import { removeLocaleSegment } from 'dibs-intl/exports/urls';

import { ServerVarsContext } from '../../global/ServerVarsContext/ServerVarsContext';
import { MAX_AD_LIMIT } from '../../constants/sbConstants';
import { getNumberOfTiles, getPageSizeOfTiles } from '../../utils/adHelpers';

export class SbSharedRefetchContainerComponent extends Component {
    constructor() {
        super();
        this.personalizedFilterValues = [];
        this.refetchCallback = this.refetchCallback.bind(this);
    }

    componentDidMount() {
        const { itemSearch = {} } = this.props;
        const { displayUriRef, vanityUriRef } = itemSearch;

        this.props.updateUriRef({ uriRef: displayUriRef });

        this.initRouter();

        if (vanityUriRef) {
            // for force facet  or semantic or spell corrected search pages, graphql returned an updated uri,
            // update it in the browser
            this.router.navigate(vanityUriRef, {
                replace: true,
            });
        }

        this.personalizedFilterValues = getPersonalizedFilterValues();

        this.props.updateZipCode(getUserZipCode());
    }

    componentDidUpdate(prevProps) {
        const {
            regionalInfo,
            generatedUriRef,
            page: newPage,
            onSbRefetch,
            pageSize: newPageSize,
            shippingFilters,
            userZipCode,
            priceBookName,
            refetchInFlight,
        } = this.props;

        const userCountryCode = getUserSessionCountryCode();

        const { isSbAdsEnabled } = this.context;
        /**
         * Make sure we have query params in both uri refs before comparing them to see if they've
         * changed.
         */
        const oldUriRef = addCdnQueryParams(prevProps.generatedUriRef);
        const newUriRef = addCdnQueryParams(generatedUriRef);
        const uriRefChanged = oldUriRef !== '' && newUriRef !== oldUriRef;
        const pageSizeChanged = newPageSize !== prevProps.pageSize;
        /**
         * Use `isRefetchInFlight` to prevent second refetch for refine search pages like `/furniture/?q=black%20tabl`.
         * An alternative fix would be to remove `this.props.updateUriRef({ uriRef: displayUriRef });`, but
         * we are unsure whether remove it will break other pages.
         *
         * See https://github.com/1stdibs/ferrum/pull/15355/files#r828952656
         */
        const isRefetchInFlight = refetchInFlight || prevProps.refetchInFlight;
        if ((uriRefChanged || pageSizeChanged) && !isRefetchInFlight) {
            const page =
                newPage === prevProps.page &&
                // if uriRef changed because filter added/removed, reset to page 1
                (uriRefChanged ||
                    // make sure page size actually changed and not just from page with ads to page with no ads
                    Math.abs(newPageSize - prevProps.pageSize) > MAX_AD_LIMIT)
                    ? 1
                    : newPage;

            const variables = {
                page,
                uriRef: page === newPage ? newUriRef : getNewPaginationUriRef(newUriRef, page),
                followSearchPages: [generatedUriRef],
                followSearchTypes: getFollowTypeArrayFromUri(generatedUriRef),
                // reset number of tiles base on the new uriRef
                first: pageSizeChanged
                    ? newPageSize
                    : getNumberOfTiles({
                          path: generatedUriRef,
                          // we need to map old numOfTiles back to default page size (60/120) to get correct numOfTiles for this new uriRef
                          pageSize: getPageSizeOfTiles({
                              uriRef: prevProps.generatedUriRef,
                              numOfTiles: prevProps.pageSize,
                          }),
                          isSbAdsEnabled,
                      }),
                disableForceFacet: true,
                userCountryCode,
                priceBookName,
            };

            // 1. set state to loading
            this.props.toggleRequestInFlight(true);

            // 2. do refetch
            onSbRefetch(variables, null, this.refetchCallback);

            // 3. update to new relay vars
            this.props.updateRelayVariables(variables);
        }

        // Handle ?personalizedfilter= query params
        const regionsByZipCode = getUserSessionRegions() || regionalInfo?.[0]?.regionsByZipCode;
        if (this.personalizedFilterValues.length && regionsByZipCode) {
            const personalizedFilterUriRef = (this.personalizedFilterValues || []).reduce(
                (uriRef, filter) => {
                    if (
                        shouldApplyPersonalizedFilter({ filter, shippingFilters }) &&
                        getPersonalizedFilterAction(filter)
                    ) {
                        return getPersonalizedFilterUriRef({
                            filterName: filter,
                            filterValues: regionsByZipCode,
                            uriRef: uriRef || generatedUriRef,
                        });
                    }
                    return uriRef;
                },
                ''
            );

            if (personalizedFilterUriRef && personalizedFilterUriRef !== generatedUriRef) {
                this.props.updateUriRef({
                    uriRef: personalizedFilterUriRef,
                });
            }
        } else if (hasPersonalizedFilterQueryParam()) {
            const urlWithoutPersonalizedFilter = getUrlWithoutPersonalizedFilterParam();
            this.router.navigate(urlWithoutPersonalizedFilter);
        }

        if (prevProps.fetchRegionalInfo && userZipCode !== prevProps.userZipCode) {
            onSbRefetch({ userZipCode, userCountryCode }, null, () => {});
        }
    }

    refetchCallback() {
        const displayUriRef = this.props.itemSearch?.displayUriRef;

        this.props.toggleRequestInFlight(false);

        this.props.updateUriRef({ uriRef: displayUriRef });

        if (displayUriRef) {
            this.router.navigate(displayUriRef);
        }
    }

    initRouter() {
        this.router = new FindingRouter({ routes });
        this.router.history.listen(({ pathname, search }, action) => {
            if (action === 'POP') {
                this.props.updateUriRef({
                    uriRef: removeLocaleSegment(stripRoutingSegment(`${pathname}${search}`)),
                });
            }
        });
    }

    render() {
        const { refetchInFlight, shouldShowSpinner } = this.props;
        if (refetchInFlight && shouldShowSpinner) {
            return <LoadingComponent />;
        } else {
            return null;
        }
    }
}

SbSharedRefetchContainerComponent.contextType = ServerVarsContext;

SbSharedRefetchContainerComponent.defaultProps = {
    shouldShowSpinner: true,
};

SbSharedRefetchContainerComponent.propTypes = {
    updateUriRef: PropTypes.func.isRequired,
    onSbRefetch: PropTypes.func.isRequired,
    itemSearch: PropTypes.object.isRequired,
    page: PropTypes.number.isRequired,
    pageSize: PropTypes.number.isRequired,
    // TODO INFRA: Should be required
    generatedUriRef: PropTypes.string,
    toggleRequestInFlight: PropTypes.func.isRequired,
    updateRelayVariables: PropTypes.func.isRequired,
    regionalInfo: PropTypes.array,
    updateZipCode: PropTypes.func.isRequired,
    shippingFilters: PropTypes.array,
    refetchInFlight: PropTypes.bool.isRequired,
    userZipCode: PropTypes.string,
    fetchRegionalInfo: PropTypes.bool,
    shouldShowSpinner: PropTypes.bool,
    priceBookName: PropTypes.string,
};

function mapStateToProps(state) {
    const { filters, relayVariables } = state;
    const { generatedUriRef, page } = filters;
    const {
        variables: { first, userZipCode, fetchRegionalInfo, priceBookName },
    } = relayVariables;

    return {
        page,
        generatedUriRef,
        pageSize: first,
        refetchInFlight: filters.refetchInFlight,
        userZipCode,
        fetchRegionalInfo,
        priceBookName,
    };
}

export const SbSharedRefetchContainer = createFragmentContainer(
    connect(mapStateToProps, {
        updateUriRef,
        updateRelayVariables,
        toggleRequestInFlight,
        updateZipCode,
    })(SbSharedRefetchContainerComponent),
    {
        itemSearch: graphql`
            fragment SbSharedRefetchContainer_itemSearch on ItemSearchQueryConnection {
                vanityUriRef
                displayUriRef
            }
        `,
        regionalInfo: graphql`
            fragment SbSharedRefetchContainer_regionalInfo on RegionalInfo @relay(plural: true) {
                regionsByZipCode {
                    displayName
                    urlLabel
                }
            }
        `,
        shippingFilters: graphql`
            fragment SbSharedRefetchContainer_shippingFilters on ShippingFilterType
            @relay(plural: true) {
                ...personalizedFiltersHelpers_shippingFilters @relay(mask: false)
            }
        `,
    }
);
