import PropTypes from 'prop-types';
import get from 'lodash.get';
import classnames from 'classnames';
import { FormattedMessage } from 'dibs-react-intl';
import { createFragmentContainer, graphql } from 'react-relay/legacy';
import { Link } from 'dibs-elements/exports/Link';

import copy from './CategoryTilesCopy.json';
import styles from './CategoryTiles.scss';

// used to render placeholder modules for data that
// will be fetched later, to avoid jumpy page
const optimisticModules = [{}, {}];

export function TileMain({ vertical, onMainTileClick = () => {} }) {
    const imageStyle = { backgroundImage: `url("${vertical.imageUrl}")` };
    return (
        <Link
            href={vertical.url}
            className={styles.tileLink}
            dataTn="category-main-tile-link"
            onClick={e => onMainTileClick(e, { vertical })}
        >
            <div className={styles.title}>
                <FormattedMessage
                    id="dbl.CategoryTiles.main_title"
                    defaultMessage={`
                        {type, select,
                            Furniture {Furniture}
                            Art {Fine Art}
                            Jewelry {Jewelry & Watches}
                            Fashion {Fashion}
                            Eero {Eero Saarinen}
                            Roy {Roy Lichtenstein}
                            Cartier {Cartier}
                            other {Hermès}
                        }
                    `}
                    values={{ type: vertical.category }}
                />
            </div>
            <div className={styles.imageWrap} style={imageStyle} />
        </Link>
    );
}

TileMain.propTypes = {
    onMainTileClick: PropTypes.func,
    vertical: PropTypes.object.isRequired,
};

export function TileModules({ vertical, modules, onModuleTileClick = () => {} }) {
    if (!modules.length) {
        return null;
    }
    return (
        <div className={styles.modules} data-tn="tile-modules">
            <div className={styles.tileSubTitle}>
                <FormattedMessage
                    id="dbl.CategoryTiles.module_title"
                    defaultMessage={`
                        {type, select,
                            Furniture {Popular in Furniture}
                            Art {Popular in Fine Art}
                            Jewelry {Popular in Jewelry & Watches}
                            other {Popular in Fashion}
                        }
                    `}
                    values={{ type: vertical.category }}
                />
            </div>
            <div className={styles.categoryProducts}>
                {modules.map((item, index) => {
                    return (
                        <div className={styles.categoryProduct} key={`${index}${item.url}`}>
                            <Link
                                href={item.url}
                                title={item.displayName}
                                dataTn="category-module-tile-link"
                                className={styles.categoryProductLink}
                                onClick={e => onModuleTileClick(e, { vertical, index })}
                            >
                                <span className={styles.categoryProductImageWrap}>
                                    {item.image && (
                                        <img
                                            className={styles.categoryProductImage}
                                            src={item.image}
                                            alt={item.displayName}
                                        />
                                    )}
                                </span>
                                <span className={styles.categoryProductTitle}>
                                    {item.displayName}
                                </span>
                            </Link>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

TileModules.propTypes = {
    onModuleTileClick: PropTypes.func,
    vertical: PropTypes.object.isRequired,
    modules: PropTypes.array.isRequired,
};

export function CategoryTilesComponent({
    viewer,
    showModules,
    withOptimisticModules,
    onMainTileClick,
    onModuleTileClick,
}) {
    return (
        <div className={styles.tiles}>
            {copy.items.map(vertical => {
                const { type, category } = vertical;
                const isHorizontal = type === 'horizontal';
                let modules = [];
                if (category && isHorizontal && showModules) {
                    modules =
                        get(viewer, `trending${category}`) ||
                        (withOptimisticModules && optimisticModules);
                }
                const hasModules = modules && modules.length > 0;
                const tileClass = classnames(styles.tile, {
                    [styles.tileSmall]: type === 'tile',
                    [styles.tileBig]: isHorizontal,
                });
                const tileSpacingClass = classnames(styles.tileSpacing, {
                    [styles.hasModules]: hasModules,
                });

                return (
                    <div className={tileClass} key={vertical.imageUrl}>
                        <div className={tileSpacingClass}>
                            <TileMain vertical={vertical} onMainTileClick={onMainTileClick} />
                            {hasModules && (
                                <TileModules
                                    modules={modules}
                                    vertical={vertical}
                                    onModuleTileClick={onModuleTileClick}
                                />
                            )}
                        </div>
                    </div>
                );
            })}
        </div>
    );
}

CategoryTilesComponent.propTypes = {
    viewer: PropTypes.object,
    showModules: PropTypes.bool,
    withOptimisticModules: PropTypes.bool,
    onMainTileClick: PropTypes.func,
    onModuleTileClick: PropTypes.func,
};

export const CategoryTiles = createFragmentContainer(CategoryTilesComponent, {
    viewer: graphql`
        fragment CategoryTiles_viewer on Viewer {
            ... on Viewer @include(if: $showModules) {
                trendingFurniture: trending(
                    trendType: CATEGORY
                    parentCategory: FURNITURE
                    numItems: 2
                    categoryLevels: 2
                ) {
                    displayName
                    url
                    image
                }
                trendingArt: trending(
                    trendType: CATEGORY
                    parentCategory: ART
                    numItems: 2
                    categoryLevels: 2
                ) {
                    displayName
                    url
                    image
                }
                trendingFashion: trending(
                    trendType: CATEGORY
                    parentCategory: FASHION
                    numItems: 2
                    categoryLevels: 2
                ) {
                    displayName
                    url
                    image
                }
                trendingJewelry: trending(
                    trendType: CATEGORY
                    parentCategory: JEWELRY
                    numItems: 2
                    categoryLevels: 2
                ) {
                    displayName
                    url
                    image
                }
            }
        }
    `,
});
