import { Component } from 'react';
import PropTypes from 'prop-types';
import { Carousel } from 'dibs-carousel';
import { SharedCarouselTracking } from './SharedCarouselTracking';

class TrackingCarousel extends Component {
    constructor() {
        super();

        this.handlePageChange = this.handlePageChange.bind(this);
    }

    handlePageChange({ page, change }) {
        if (change === 'next') {
            this.props.onNextPage({ page });
        } else {
            this.props.onPreviousPage({ page });
        }
    }

    render() {
        const {
            items,
            itemsPerPage,
            renderItem,
            onInitialDisplay,
            onItemImpression,
            classNames,
            dataTn,
            actionLabel,
        } = this.props;

        return (
            <SharedCarouselTracking
                items={items}
                itemsPerPage={itemsPerPage}
                onInitialDisplay={onInitialDisplay}
                onItemImpression={onItemImpression}
                onPageChange={this.handlePageChange}
                actionLabel={actionLabel}
            >
                {({ handleIndexChange }) => (
                    <Carousel
                        classNames={classNames}
                        totalItems={items.length}
                        renderItem={renderItem}
                        itemsPerPage={itemsPerPage}
                        onIndexChange={handleIndexChange}
                        step={itemsPerPage}
                        dataTn={dataTn}
                    />
                )}
            </SharedCarouselTracking>
        );
    }
}

TrackingCarousel.propTypes = {
    classNames: PropTypes.shape({
        list: PropTypes.string,
    }),
    items: PropTypes.array.isRequired,
    itemsPerPage: PropTypes.number.isRequired,
    onInitialDisplay: PropTypes.func,
    renderItem: PropTypes.func.isRequired,
    onItemImpression: PropTypes.func.isRequired,
    onNextPage: PropTypes.func,
    onPreviousPage: PropTypes.func,
    dataTn: PropTypes.string,
    actionLabel: PropTypes.string,
};

TrackingCarousel.defaultProps = {
    onInitialDisplay: () => {},
    onNextPage: () => {},
    onPreviousPage: () => {},
};

export { TrackingCarousel };
