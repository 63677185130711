import { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { graphql, createFragmentContainer } from 'react-relay/legacy';

import { FormattedMessage } from 'dibs-react-intl';
import { ExpandingArea } from 'dibs-elements/exports/ExpandingArea';
import { SearchTag } from 'dibs-elements/exports/SearchTag';

import { useMeasurementUnit } from '../useMeasurementUnit';
import { useRingMeasurementType } from '../hooks/ringSize/useRingMeasurementType';
import { getFilterValues } from '../SbSharedRefineMenu/sbSharedRefineMenuHelpers';
import {
    getAppliedFilterValues,
    closeAppliedFilterHandler,
    clearAllAppliedFiltersHandler,
    getValidSharedAppliedFilters,
} from '../SbSharedAppliedFilters/sbSharedAppliedFiltersHelpers';
import { isBatchAppliedFilter } from '../SbRespRefineMenu/SbRespRefineMenuFilters/sbRespRefineMenuFiltersMap';
import { SbRespAppliedFiltersClearAll } from './SbRespAppliedFiltersClearAll';
import { SbRespAppliedFiltersItem as SbSharedAppliedFiltersItem } from './SbRespAppliedFiltersItem';

import styles from './SbRespAppliedFilters.scss';

const FILTER_THRESHOLD = 5;

export const SbRespAppliedFiltersComponent = props => {
    const { itemSearch, currency, onCloseClick, onClearAllClick, user, generatedUriRef } = props;
    const { appliedFilters, filters } = itemSearch;
    const [isViewingMoreFilters, setIsViewingMoreFilters] = useState(false);

    const validAppliedFilters = getValidSharedAppliedFilters(appliedFilters);

    const [measurementUnit] = useMeasurementUnit({ user, itemSearch });
    const [ringMeasurementType] = useRingMeasurementType({ currency, itemSearch });

    if (!validAppliedFilters.length) {
        return null;
    }

    const tags = validAppliedFilters.reduce((acc, appliedFilter) => {
        const isBatchApplied = isBatchAppliedFilter(appliedFilter.name);
        const values = getAppliedFilterValues({
            appliedFilter,
            isBatchApplied,
        });

        values.forEach((filterValue, index) => {
            acc.push(
                <SbSharedAppliedFiltersItem
                    key={`${appliedFilter.name}-${index}`}
                    currency={currency}
                    filterName={appliedFilter.name}
                    localizedFilterName={appliedFilter.localizedFilterName}
                    filterValue={isBatchApplied ? null : filterValue}
                    availableValues={getFilterValues(filters, appliedFilter.name)}
                    onCloseClick={event =>
                        onCloseClick({
                            filterName: appliedFilter.name,
                            filterValue,
                            generatedUriRef,
                            isBatchApplied,
                            event,
                        })
                    }
                    measurementUnit={measurementUnit}
                    ringMeasurementType={ringMeasurementType}
                />
            );
        });
        return acc;
    }, []);
    const numberMoreFilters = Math.max(tags.length - FILTER_THRESHOLD, 0);
    if (numberMoreFilters === 0 && isViewingMoreFilters) {
        setIsViewingMoreFilters(false);
    }
    const extraTags = tags.splice(FILTER_THRESHOLD);

    return (
        <div className={styles.container}>
            <div className={styles.appliedFilters} data-tn="applied-filters-container">
                {tags}
                {!!numberMoreFilters && (
                    <>
                        {isViewingMoreFilters && (
                            <ExpandingArea
                                expanded={isViewingMoreFilters}
                                childrenWrapperClass={styles.expandingArea}
                            >
                                {extraTags}
                            </ExpandingArea>
                        )}
                        <div
                            onClick={() => setIsViewingMoreFilters(!isViewingMoreFilters)}
                            onKeyDown={() => setIsViewingMoreFilters(!isViewingMoreFilters)}
                            className={styles.moreFiltersButtonWrapper}
                            role="button"
                            tabIndex="0"
                        >
                            <SearchTag hasDeleteButton={false} dataTn={'more-filters-button'}>
                                <div className={styles.moreFiltersButton}>
                                    {isViewingMoreFilters ? (
                                        <FormattedMessage
                                            id="sb.SbRespAppliedFilters.viewLess"
                                            defaultMessage="View Less"
                                        />
                                    ) : (
                                        <FormattedMessage
                                            id="sb.SbRespAppliedFilters.additionalFilters"
                                            defaultMessage="+ {numberMoreFilters} more"
                                            values={{ numberMoreFilters }}
                                        />
                                    )}
                                </div>
                            </SearchTag>
                        </div>
                    </>
                )}
                <SbRespAppliedFiltersClearAll
                    onClearAllClick={event => {
                        onClearAllClick({ event });
                    }}
                />
            </div>
        </div>
    );
};

SbRespAppliedFiltersComponent.defaultProps = {
    onCloseClick: () => {},
    onClearAllClick: () => {},
};

SbRespAppliedFiltersComponent.propTypes = {
    user: PropTypes.object,
    currency: PropTypes.string.isRequired,
    itemSearch: PropTypes.object.isRequired,
    // redux
    onCloseClick: PropTypes.func.isRequired,
    onClearAllClick: PropTypes.func.isRequired,
    generatedUriRef: PropTypes.string,
};

function mapDispatchToProps(dispatch, props) {
    return {
        onCloseClick: closeAppliedFilterHandler(dispatch),
        onClearAllClick: clearAllAppliedFiltersHandler(dispatch, props),
    };
}

function mapStateToProps(state) {
    return {
        generatedUriRef: state?.filters?.generatedUriRef,
    };
}

export const SbRespAppliedFilters = createFragmentContainer(
    connect(mapStateToProps, mapDispatchToProps)(SbRespAppliedFiltersComponent),
    {
        itemSearch: graphql`
            fragment SbRespAppliedFilters_itemSearch on ItemSearchQueryConnection {
                ...useMeasurementUnit_itemSearch
                ...useRingMeasurementType_itemSearch
                clearAppliedFiltersLinkReference
                filters {
                    name
                    localizedFilterName
                    values {
                        displayName
                        urlLabel
                        linkReference
                        properties {
                            mm
                            us
                        }
                    }
                }
                appliedFilters {
                    name
                    localizedFilterName
                    canBeDismissed
                    values {
                        urlLabel
                        linkReference
                        displayName
                        hexCode
                        properties {
                            mm
                            us
                        }
                    }
                }
            }
        `,
        user: graphql`
            fragment SbRespAppliedFilters_user on User {
                ...useMeasurementUnit_user
            }
        `,
    }
);
