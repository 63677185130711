import { TRADE, CONSUMER } from 'dibs-buyer-layout/exports/userTypeEnums';
import { getProductTileAdMap } from '../../utils/adHelpers';

type MetadataEntry = {
    itemId: string | null;
    clickTrackerLink: string | null;
    impressionTrackerLink: string | null;
} | null;

type Func = <T extends { serviceId: string | null }>(args: {
    readonly items: ReadonlyArray<{
        node: { item: T | null } | null;
    } | null> | null;
    readonly sponsored: {
        items: ReadonlyArray<T | null> | null;
        metadata: ReadonlyArray<MetadataEntry> | null;
    } | null;
    displayUriRef?: string | null;
    pageSize: number | string;
    isTrade?: boolean;
    isMobile: boolean;
}) => {
    readonly items: ReadonlyArray<{ node: { item: T | null } | null } | null> | null;
    sponsoredItemsIndexMetadataMap?: Record<string, Omit<MetadataEntry, 'itemId'>>;
};
type Item = NonNullable<NonNullable<ReturnType<Func>['items']>[number]>;

const SPONSORED_ROW_INDEX_RESP = [0, 5, 10, 16, 21];
const SPONSORED_ROW_INDEX_MOBILE = [0, 6, 11, 16, 22, 28, 34];
export const MIN_ITEMS_COUNT = 12;
export const MOBILE_ITEMS_PER_ROW = 2;
export const RESP_ITEMS_PER_ROW = 3;

export const getMergedSbAndSponsoredItems: Func = ({
    items,
    sponsored,
    displayUriRef,
    pageSize,
    isTrade = false,
    isMobile,
}) => {
    // If there aren't any items, don't attempt merge
    if (!items || (Array.isArray(items) && items.length === 0)) {
        return { items };
    }

    const sponsoredItems = sponsored?.items;

    // If there aren't any sponsored items, return items
    if (!sponsoredItems || (Array.isArray(sponsoredItems) && sponsoredItems.length === 0)) {
        return { items };
    }

    // If item count is less than min needed for sponsored listings, return items
    if (items.length < MIN_ITEMS_COUNT) {
        return { items };
    }

    pageSize = typeof pageSize === 'string' ? parseInt(pageSize) : pageSize;

    // If we're missing any required arguments or they are invalid, don't attempt merge
    if (
        typeof pageSize !== 'number' ||
        isNaN(pageSize) ||
        typeof isTrade !== 'boolean' ||
        typeof isMobile !== 'boolean'
    ) {
        return { items };
    }

    const itemsPerRow = isMobile ? MOBILE_ITEMS_PER_ROW : RESP_ITEMS_PER_ROW;

    const adMap = getProductTileAdMap({
        uriRef: displayUriRef || '',
        pageSize,
        pageCol: itemsPerRow,
        userType: isTrade ? TRADE : CONSUMER,
    });

    const metadataMap: Record<string, Omit<MetadataEntry, 'itemId'>> = {};

    (sponsored?.metadata || []).reduce((acc, value) => {
        if (value && typeof value.itemId === 'string') {
            const { itemId, clickTrackerLink, impressionTrackerLink } = value;
            acc[itemId] = { clickTrackerLink, impressionTrackerLink };
        }

        return acc;
    }, metadataMap);

    const mergedItems = [];

    // We don't count Doubleclick ads on mobile towards the slots because they take up their own row
    let adCount = isMobile ? 0 : Object.entries(adMap).length;

    if (!isMobile) {
        // If ad index is greater than amount of items, decrease ad total count
        for (const prop in adMap) {
            if (Object.hasOwn(adMap, prop)) {
                const adPos = parseInt(prop);
                if (adPos >= items.length) {
                    adCount--;
                }
            }
        }
    }

    const totalSlots = items.length + sponsoredItems.length + adCount;
    const sponsoredRowIndex = isMobile ? SPONSORED_ROW_INDEX_MOBILE : SPONSORED_ROW_INDEX_RESP;

    let adSlotsRemaining = adCount;
    let currRow = 0;
    let sponsoredRow: Item[] = [];
    let sponsoredItemsIndex = 0;
    let itemsIndex = 0;

    const sponsoredItemsIndexMetadataMap: Record<string, Omit<MetadataEntry, 'itemId'>> = {};

    for (let i = 0; i < totalSlots; i++) {
        if (i !== 0 && i % itemsPerRow === 0) {
            currRow++;
        }

        if (
            itemsIndex === items.length ||
            (sponsoredRowIndex.includes(currRow) && sponsoredItemsIndex < sponsoredItems.length)
        ) {
            const sponsoredItem = sponsoredItems[sponsoredItemsIndex];
            sponsoredRow.push({ node: { item: sponsoredItem } });
            sponsoredItemsIndex++;
        } else if (adSlotsRemaining === 0) {
            mergedItems.push(items[itemsIndex]);
            itemsIndex++;
        } else if (!adMap[i + 1]) {
            // adMap is 1-indexed
            mergedItems.push(items[itemsIndex]);
            itemsIndex++;
        } else {
            adSlotsRemaining--;
        }

        if (sponsoredRow.length === itemsPerRow) {
            let counter = 0;
            while (counter < itemsPerRow) {
                const sponsoredItem = sponsoredRow[counter];
                const mapIndex = mergedItems.length === 0 ? counter : mergedItems.length + counter;
                const itemId = sponsoredItem?.node?.item?.serviceId;
                if (itemId) {
                    sponsoredItemsIndexMetadataMap[mapIndex] = metadataMap[itemId];
                }

                counter++;
            }

            mergedItems.push(...sponsoredRow);
            sponsoredRow = [];
        }
    }

    return { items: mergedItems, sponsoredItemsIndexMetadataMap };
};
