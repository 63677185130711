/**
 * @generated SignedSource<<99fb09a494b4093c2047e4203bce8669>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SbRespRefineMenuSelector_viewer$data = {
  readonly " $fragmentSpreads": FragmentRefs<"SbRespRefineMenu_viewer">;
  readonly " $fragmentType": "SbRespRefineMenuSelector_viewer";
};
export type SbRespRefineMenuSelector_viewer$key = {
  readonly " $data"?: SbRespRefineMenuSelector_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"SbRespRefineMenuSelector_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SbRespRefineMenuSelector_viewer",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbRespRefineMenu_viewer"
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};

(node as any).hash = "00a3fa88a84d47670d555184bd339988";

export default node;
