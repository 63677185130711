/**
 * @generated SignedSource<<c1a29e332bd467a484dfd14b5a4a20ec>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SbRespNullSearchResults_user$data = {
  readonly " $fragmentSpreads": FragmentRefs<"SbRespFollowSearchBanner_user" | "SbSharedPageBreadcrumbs_user" | "SbSharedTitle_user" | "SharedFollowTopButton_user" | "SharedRecentSales_user" | "SharedYMAL_user">;
  readonly " $fragmentType": "SbRespNullSearchResults_user";
};
export type SbRespNullSearchResults_user$key = {
  readonly " $data"?: SbRespNullSearchResults_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"SbRespNullSearchResults_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SbRespNullSearchResults_user",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbRespFollowSearchBanner_user"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SharedYMAL_user"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SharedFollowTopButton_user"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbSharedTitle_user"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SharedRecentSales_user"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbSharedPageBreadcrumbs_user"
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "15fa21510895da09dd564d6165cb8c8f";

export default node;
