import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { createFragmentContainer, graphql } from 'react-relay/legacy';

import {
    getPersonalizedFilterValues,
    shouldApplyPersonalizedFilter,
} from '../../shared/helpers/personalizedFiltersHelpers';
import SbSharedPersonalizedFilterToastLazy from './SbSharedPersonalizedFilterToastLazy';

const SbSharedPersonalizedFilterToastComponent = ({ shippingFilters }) => {
    const [personalizedFilterValues, setPersonalizedFilterValues] = useState([]);

    useEffect(() => {
        setPersonalizedFilterValues(getPersonalizedFilterValues() || []);
    }, []);

    if (shippingFilters && personalizedFilterValues.length) {
        const personalizedFilterValue = personalizedFilterValues[0];
        if (!shouldApplyPersonalizedFilter({ personalizedFilterValue, shippingFilters })) {
            return (
                <SbSharedPersonalizedFilterToastLazy
                    personalizedFilterValue={personalizedFilterValue}
                />
            );
        }
    }

    return null;
};

SbSharedPersonalizedFilterToastComponent.propTypes = {
    shippingFilters: PropTypes.array,
};

export const SbSharedPersonalizedFilterToast = createFragmentContainer(
    SbSharedPersonalizedFilterToastComponent,
    {
        shippingFilters: graphql`
            fragment SbSharedPersonalizedFilterToast_shippingFilters on ShippingFilterType
            @relay(plural: true) {
                ...personalizedFiltersHelpers_shippingFilters @relay(mask: false)
            }
        `,
    }
);
