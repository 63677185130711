import { FC } from 'react';
import { Spinner } from 'dibs-elements/exports/Spinner';

import styles from './LoadingSpinner.scss';

export const LoadingSpinner: FC = () => {
    return (
        <div className={styles.wrapper}>
            <div className={styles.spinnerContainer}>
                <Spinner />
            </div>
        </div>
    );
};
