/**
 * @generated SignedSource<<4b47c497ac5b85a28457e3bb74335247>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type LazyImageWrapper_item$data = {
  readonly photos: ReadonlyArray<{
    readonly placeholder: string | null;
  } | null> | null;
  readonly " $fragmentType": "LazyImageWrapper_item";
};
export type LazyImageWrapper_item$key = {
  readonly " $data"?: LazyImageWrapper_item$data;
  readonly " $fragmentSpreads": FragmentRefs<"LazyImageWrapper_item">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "LazyImageWrapper_item",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "limit",
          "value": 1
        }
      ],
      "concreteType": "ItemPhoto",
      "kind": "LinkedField",
      "name": "photos",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "placeholder",
          "storageKey": null
        }
      ],
      "storageKey": "photos(limit:1)"
    }
  ],
  "type": "Item",
  "abstractKey": null
};

(node as any).hash = "66b63b781be0b9150b305761f2aac314";

export default node;
