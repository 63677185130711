import { PAGE_TYPE } from 'dibs-constants/exports/pageTypes';

export const TILE_AD_KEY = 'productTile';
export const BILLBOARD_AD_KEY = 'billboard';
export type AdKey = typeof TILE_AD_KEY | typeof BILLBOARD_AD_KEY;

export const ELEMENT_ID_BILLBOARD = 'ad-billboard'; // update 'finding-wide-ad' elementId values to this const
export const ELEMENT_ID_BILLBOARD_MOBILE = 'ad-billboard-mobile'; // update 'finding-mobile-ad' elementId values to this const
export const ELEMENT_ID_BILLBOARD_TABLET = 'ad-billboard-tablet'; // update 'finding-wide-tablet-ad' elementId values to this const
export const ELEMENT_ID_PRODUCT_TILE_AD_PREFIX = 'tile-ad';

export const PRODUCT_TILE_WIDTH = 331;
export const PRODUCT_TILE_HEIGHT = 419;
export const BILLBOARD_DESKTOP_WIDTH = 970;
export const BILLBOARD_DESKTOP_HEIGHT = 250;
export const BILLBOARD_TABLET_WIDTH = 746;
export const BILLBOARD_TABLET_HEIGHT = 192;
export const BILLBOARD_MOBILE_WIDTH = 320;
export const BILLBOARD_MOBILE_HEIGHT = 82;

type Ad = {
    // making this optional allows for the `createTileData`
    adId?: string;
};

export type AdProductTile = Ad & {
    elementId: string;
    width: typeof PRODUCT_TILE_WIDTH;
    height: typeof PRODUCT_TILE_HEIGHT;
};

export type AdBillboard = {
    desktop?: Ad & {
        elementId: typeof ELEMENT_ID_BILLBOARD;
        width: typeof BILLBOARD_DESKTOP_WIDTH;
        height: typeof BILLBOARD_DESKTOP_HEIGHT;
    };
    tablet?: Ad & {
        elementId: typeof ELEMENT_ID_BILLBOARD_TABLET;
        width: typeof BILLBOARD_TABLET_WIDTH;
        height: typeof BILLBOARD_TABLET_HEIGHT;
    };
    mobile?: Ad & {
        elementId: typeof ELEMENT_ID_BILLBOARD_MOBILE;
        width: typeof BILLBOARD_MOBILE_WIDTH;
        height: typeof BILLBOARD_MOBILE_HEIGHT;
    };
};

export type AdSkyscraper = Ad & {
    elementId: string;
    width: number;
    height: number;
};

export type AdData = {
    [n: string]: AdProductTile[] | AdBillboard | AdSkyscraper | undefined;
    [TILE_AD_KEY]: AdProductTile[];
    [BILLBOARD_AD_KEY]?: AdBillboard;
};

const createProductTileData = (productTileData?: Ad[]): AdData['productTile'] => {
    let productTile: AdData['productTile'] = [];
    if (Array.isArray(productTileData)) {
        productTile = productTileData.map((tile, index) => {
            return {
                adId: tile.adId,
                elementId: `${ELEMENT_ID_PRODUCT_TILE_AD_PREFIX}-${index}`,
                width: PRODUCT_TILE_WIDTH,
                height: PRODUCT_TILE_HEIGHT,
            };
        });
    }
    return productTile;
};

const createBillboardData = (billboardData?: {
    desktop: Ad;
    mobile?: Ad;
    tablet?: Ad;
}): AdData['billboard'] => {
    const { desktop, tablet, mobile } = billboardData || {};

    const data: AdData['billboard'] = {};

    if (desktop) {
        data.desktop = {
            adId: desktop.adId,
            elementId: ELEMENT_ID_BILLBOARD,
            width: BILLBOARD_DESKTOP_WIDTH,
            height: BILLBOARD_DESKTOP_HEIGHT,
        };
    }

    if (tablet) {
        data.tablet = {
            adId: tablet.adId,
            elementId: ELEMENT_ID_BILLBOARD_TABLET,
            width: BILLBOARD_TABLET_WIDTH,
            height: BILLBOARD_TABLET_HEIGHT,
        };
    }

    if (mobile) {
        data.mobile = {
            adId: mobile.adId,
            elementId: ELEMENT_ID_BILLBOARD_MOBILE,
            width: BILLBOARD_MOBILE_WIDTH,
            height: BILLBOARD_MOBILE_HEIGHT,
        };
    }

    return data;
};

// only exporting for testing
export const generateAdData = ({
    productTileData,
    billboardData,
}: {
    productTileData?: Ad[];
    billboardData?: { desktop: Ad; mobile?: Ad; tablet?: Ad };
}): AdData => {
    return {
        productTile: createProductTileData(productTileData),
        billboard: createBillboardData(billboardData),
    };
};

const AD_JEWELRY = {
    ...generateAdData({
        billboardData: {
            desktop: {
                adId: '/5391766/Jewelry_BottomBillboardBanner',
            },
            tablet: {
                adId: '/5391766/Small_Jewelry_BillboardBanner',
            },
            mobile: {
                adId: '/5391766/Jewelry_BillboardBanner_Mobile',
            },
        },
        productTileData: [
            { adId: '/5391766/FeaturedItem_Jewelry_ONE' },
            { adId: '/5391766/FeaturedItem_Jewelry_TWO' },
            { adId: '/5391766/FeaturedItem_Jewelry_THREE' },
            { adId: '/5391766/FeaturedItem_Jewelry_FOUR' },
        ],
    }),
};

const AD_FASHION = {
    ...generateAdData({
        productTileData: [
            { adId: '/5391766/FeaturedItem_Fashion_ONE' },
            { adId: '/5391766/FeaturedItem_Fashion_TWO' },
            { adId: '/5391766/FeaturedItem_Fashion_THREE' },
            { adId: '/5391766/FeaturedItem_Fashion_FOUR' },
        ],
        billboardData: {
            desktop: {
                adId: '/5391766/Fashion_BillboardBanner',
            },
            tablet: {
                adId: '/5391766/Small_Fashion_BillboardBanner',
            },
            mobile: {
                adId: '/5391766/Fashion_BillboardBanner_Mobile',
            },
        },
    }),
};

const AD_FURNITURE = {
    ...generateAdData({
        billboardData: {
            desktop: {
                adId: '/5391766/Bottom_Billboard_Banner',
            },
            tablet: {
                adId: '/5391766/Small_Furniture_BillboardBanner',
            },
            mobile: {
                adId: '/5391766/Bottom_Billboard_Banner_Mobile',
            },
        },
        productTileData: [
            { adId: '/5391766/Featured_Item_v2_ONE' },
            { adId: '/5391766/Featured_Item_v2_TWO' },
            { adId: '/5391766/Featured_Item_v2_THREE' },
            { adId: '/5391766/Featured_Item_v2_FOUR' },
        ],
    }),
};

const AD_ART = {
    ...generateAdData({
        billboardData: {
            desktop: {
                adId: '/5391766/FineArt_BottomBillboard',
            },
            tablet: {
                adId: '/5391766/Small_FineArt_BillboardBanner',
            },
            mobile: {
                adId: '/5391766/FineArt_BillboardBanner_Mobile',
            },
        },
        productTileData: [
            { adId: '/5391766/FeaturedItem_FineArt_One' },
            { adId: '/5391766/FeaturedItem_FineArt_Two' },
        ],
    }),
};

const AD_SATURDAY_SALE = generateAdData({
    billboardData: {
        desktop: {
            adId: '/5391766/Bottom_Billboard_Banner_Sale',
        },
        mobile: {
            adId: '/5391766/Bottom_Billboard_Banner_Sale',
        },
    },
    productTileData: [
        { adId: '/5391766/Featured_Item_Banner_Sale_Landing_1' },
        { adId: '/5391766/Featured_Item_Banner_Sale_Landing_2' },
        { adId: '/5391766/Featured_Item_Banner_Sale_Landing_3' },
        { adId: '/5391766/Featured_Item_Banner_Sale_Landing_4' },
    ],
});

const AD_DEFAULT = generateAdData({
    billboardData: {
        desktop: {
            adId: '/5391766/Bottom_Billboard_Banner',
        },
    },
});

const AD_CATALOGUE_COLLECTION = generateAdData({
    billboardData: {
        desktop: {
            adId: '/5391766/Bottom_Billboard_Banner_Catalogue',
        },
        mobile: {
            adId: '/5391766/Bottom_Billboard_Banner_Catalogue_Mobile',
        },
    },
});

const AD_HOLIDAY_COLLECTION = generateAdData({
    productTileData: [
        { adId: '/5391766/Featured_Item_Banner_Holiday_Collection_2020_1' },
        { adId: '/5391766/Featured_Item_Banner_Holiday_Collection_2020_2' },
        { adId: '/5391766/Featured_Item_Banner_Holiday_Collection_2020_3' },
        { adId: '/5391766/Featured_Item_Banner_Holiday_Collection_2020_4' },
    ],
    billboardData: {
        desktop: {
            adId: '/5391766/Bottom_Billboard_Banner_Holiday_2020',
        },
        tablet: {
            adId: '/5391766/Bottom_Billboard_Banner_Holiday_2020_Tablet',
        },
        mobile: {
            adId: '/5391766/Bottom_Billboard_Banner_Holiday_2020_Mobile',
        },
    },
});

const AD_HOLIDAY_SUB_COLLECTION = generateAdData({
    billboardData: {
        desktop: {
            adId: '/5391766/Bottom_Billboard_Banner_Holiday_2020_SubGiftGuides',
        },
        mobile: {
            adId: '/5391766/Bottom_Billboard_Banner_Holiday_2020_SubGiftGuides_Mobile',
        },
    },
});

const AD_VALENTINES_COLLECTION = generateAdData({
    productTileData: [
        { adId: '/5391766/Featured_Item_Banner_Valentine_1' },
        { adId: '/5391766/Featured_Item_Banner_Valentine_2' },
        { adId: '/5391766/Featured_Item_Banner_Valentine_3' },
        { adId: '/5391766/Featured_Item_Banner_Valentine_4' },
    ],
    billboardData: {
        desktop: {
            adId: '/5391766/Bottom_Billboard_Banner_Valentine',
        },
        mobile: {
            adId: '/5391766/Bottom_Billboard_Banner_Valentine_Mobile',
        },
    },
});

const AD_ENGAGEMENT_RINGS_COLLECTION = generateAdData({
    productTileData: [
        { adId: '/5391766/Featured_Item_Banner_Engagement_Rings_1' },
        { adId: '/5391766/Featured_Item_Banner_Engagement_Rings_2' },
        { adId: '/5391766/Featured_Item_Banner_Engagement_Rings_3' },
        { adId: '/5391766/Featured_Item_Banner_Engagement_Rings_4' },
    ],
});

const AD_MOTHERS_DAY_COLLECTION = generateAdData({
    productTileData: [
        { adId: '/5391766/Featured_Item_Banner_Mothers_Day_1' },
        { adId: '/5391766/Featured_Item_Banner_Mothers_Day_2' },
        { adId: '/5391766/Featured_Item_Banner_Mothers_Day_3' },
        { adId: '/5391766/Featured_Item_Banner_Mothers_Day_4' },
    ],
    billboardData: {
        desktop: {
            adId: '/5391766/Bottom_Billboard_Banner_Mothers_Day',
        },
        mobile: {
            adId: '/5391766/Bottom_Billboard_Banner_Mothers_Day_Mobile',
        },
    },
});

const AD_FATHERS_DAY_COLLECTION = generateAdData({
    productTileData: [
        { adId: '/5391766/Featured_Item_Banner_Fathers_Day_1' },
        { adId: '/5391766/Featured_Item_Banner_Fathers_Day_2' },
        { adId: '/5391766/Featured_Item_Banner_Fathers_Day_3' },
        { adId: '/5391766/Featured_Item_Banner_Fathers_Day_4' },
    ],
});

const AD_SPRING_CATALOGUE = generateAdData({
    billboardData: {
        desktop: {
            adId: '/5391766/Bottom_Billboard_Banner_Spring_Catalogue',
        },
        mobile: {
            adId: '/5391766/Bottom_Billboard_Banner_Spring_Catalogue_Mobile',
        },
    },
});

const AD_FALL_CATALOGUE = generateAdData({
    billboardData: {
        desktop: {
            adId: '/5391766/Bottom_Billboard_Banner_Fall_Catalogue',
        },
        mobile: {
            adId: '/5391766/Bottom_Billboard_Banner_Fall_Catalogue_Mobile',
        },
    },
});

const AD_NEW_ARRIVALS = generateAdData({
    productTileData: [
        { adId: '/5391766/Featured_Item_Banner_New_Arrivals_1' },
        { adId: '/5391766/Featured_Item_Banner_New_Arrivals_2' },
        { adId: '/5391766/Featured_Item_Banner_New_Arrivals_3' },
        { adId: '/5391766/Featured_Item_Banner_New_Arrivals_4' },
    ],
    billboardData: {
        desktop: {
            adId: '/5391766/Bottom_Billboard_Banner_New_Arrivals',
        },
        mobile: {
            adId: '/5391766/Bottom_Billboard_Banner_New_Arrivals_Mobile',
        },
    },
});

export const sbAdMap: { [n: string]: AdData } = {
    jewelry: AD_JEWELRY,
    fashion: AD_FASHION,
    furniture: AD_FURNITURE,
    art: AD_ART,
    contemporary: AD_FURNITURE,
    'saturday-sale': AD_SATURDAY_SALE,
    'design-gestalt': AD_CATALOGUE_COLLECTION,
    'holiday-gift-guide': AD_HOLIDAY_COLLECTION,
    'art-gifts': AD_HOLIDAY_SUB_COLLECTION,
    'jewelry-gifts': AD_HOLIDAY_SUB_COLLECTION,
    'rare-gifts': AD_HOLIDAY_SUB_COLLECTION,
    'iconic-gifts': AD_HOLIDAY_SUB_COLLECTION,
    'valentines-gift-guide': AD_VALENTINES_COLLECTION,
    'exquisite-engagement-rings': AD_ENGAGEMENT_RINGS_COLLECTION,
    'mothers-day-collection': AD_MOTHERS_DAY_COLLECTION,
    'fathers-day-collection': AD_FATHERS_DAY_COLLECTION,
    'spring-catalogue': AD_SPRING_CATALOGUE,
    'fall-catalogue': AD_FALL_CATALOGUE,
    'new-arrivals': AD_NEW_ARRIVALS,
    default: AD_DEFAULT,
} as const;

export const sbAdExcludeMap: Record<string, readonly string[]> = {
    [PAGE_TYPE.MORE_FROM_SELLER]: [TILE_AD_KEY],
    [PAGE_TYPE.DEALERS]: [TILE_AD_KEY],
} as const;
