import PropTypes from 'prop-types';
import { createFragmentContainer, graphql } from 'react-relay/legacy';

// Components
import { SkyscraperAd } from '../../components/global/Ads/SkyscraperAd';

// Helpers
import { getSkyscraperAdData } from '../../utils/adHelpers';

const SbSharedSkyscraperAdComponent = ({ itemSearch }) => {
    const { displayUriRef } = itemSearch;
    const skyscraperAdData = getSkyscraperAdData(displayUriRef);

    if (!skyscraperAdData) {
        return null;
    }

    return <SkyscraperAd {...skyscraperAdData} />;
};

SbSharedSkyscraperAdComponent.propTypes = {
    itemSearch: PropTypes.object.isRequired,
};

export const SbSharedSkyscraperAd = createFragmentContainer(SbSharedSkyscraperAdComponent, {
    itemSearch: graphql`
        fragment SbSharedSkyscraperAd_itemSearch on ItemSearchQueryConnection {
            displayUriRef
        }
    `,
});
