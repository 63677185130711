import PropTypes from 'prop-types';
import { ComponentLoader } from '../../global/ComponentLoader/ComponentLoader';

const importFunc = () => {
    return import(
        /* webpackChunkName: "TradePromoCode" */
        './TradePromo'
    ).then(({ TradePromo }) => TradePromo);
};

export function TradePromoLoader(props) {
    return <ComponentLoader {...props} importFunc={importFunc} useLoadingComponent={false} load />;
}

TradePromoLoader.propTypes = {
    user: PropTypes.object,
};
