import { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Visibility } from './Visibility';

class SharedCarouselTracking extends Component {
    constructor() {
        super();

        this.initialyDisplayed = false;

        this.handleInitialDisplay = this.handleInitialDisplay.bind(this);
        this.handleIndexChange = this.handleIndexChange.bind(this);
        this.handlePageChange = this.handlePageChange.bind(this);

        this.state = {
            hasTrackingFired: {},
            shouldFireInitialTracking: false,
        };
    }

    trackImpressions(page) {
        const { hasTrackingFired } = this.state;

        if (!hasTrackingFired[page]) {
            const { items, onItemImpression, actionLabel } = this.props;
            const itemsPerPage = Math.floor(this.props.itemsPerPage);
            const startIndex = page * itemsPerPage;
            const visibleItems = items.slice(startIndex, startIndex + itemsPerPage);

            onItemImpression({
                items: visibleItems,
                index: startIndex,
                actionLabel,
            });

            hasTrackingFired[page] = true;

            this.setState({
                hasTrackingFired,
            });
        }
    }

    handleInitialDisplay() {
        if (!this.initialyDisplayed) {
            this.props.onInitialDisplay();
            this.trackImpressions(0);

            this.initialyDisplayed = true;
        }
    }

    handleIndexChange({ prev, index, change }) {
        const itemsPerPage = Math.floor(this.props.itemsPerPage);
        const prevPage = Math.ceil(prev / itemsPerPage);
        const page = Math.ceil(index / itemsPerPage);

        if (page !== prevPage) {
            this.props.onPageChange({ page, change });
            this.trackImpressions(page);
        }
    }

    handlePageChange(page) {
        this.props.onPageChange(page);
        this.trackImpressions(page);
    }

    render() {
        return (
            <Fragment>
                <Visibility onContentVisible={this.handleInitialDisplay} />
                {this.props.children({
                    handleIndexChange: this.handleIndexChange,
                    handlePageChange: this.handlePageChange,
                })}
            </Fragment>
        );
    }
}

SharedCarouselTracking.propTypes = {
    items: PropTypes.array.isRequired,
    itemsPerPage: PropTypes.number.isRequired,
    onInitialDisplay: PropTypes.func,
    onItemImpression: PropTypes.func.isRequired,
    onPageChange: PropTypes.func,
    children: PropTypes.func.isRequired,
    actionLabel: PropTypes.string,
};

SharedCarouselTracking.defaultProps = {
    onInitialDisplay: () => {},
    onPageChange: () => {},
};

export { SharedCarouselTracking };
