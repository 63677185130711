/**
 * @generated SignedSource<<b26b0382443990c52acd7e04b961b708>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SbRespRefineMenuSelector_user$data = {
  readonly " $fragmentSpreads": FragmentRefs<"SbRespRefineMenu_user">;
  readonly " $fragmentType": "SbRespRefineMenuSelector_user";
};
export type SbRespRefineMenuSelector_user$key = {
  readonly " $data"?: SbRespRefineMenuSelector_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"SbRespRefineMenuSelector_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SbRespRefineMenuSelector_user",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbRespRefineMenu_user"
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "ee7af77e836745da5513d2f7f4f23534";

export default node;
