/**
 * @generated SignedSource<<7cf412375173b203a495774319b7b358>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SharedCarouselWrapper_item$data = ReadonlyArray<{
  readonly browseUrl: string | null;
  readonly categoryCode: string | null;
  readonly categoryPath: string | null;
  readonly contemporaryTrackingString: string;
  readonly firstPhotoWebPath: string | null;
  readonly localizedPdpUrl: string | null;
  readonly seller: {
    readonly serviceId: string | null;
  } | null;
  readonly serviceId: string | null;
  readonly title: string | null;
  readonly " $fragmentType": "SharedCarouselWrapper_item";
}>;
export type SharedCarouselWrapper_item$key = ReadonlyArray<{
  readonly " $data"?: SharedCarouselWrapper_item$data;
  readonly " $fragmentSpreads": FragmentRefs<"SharedCarouselWrapper_item">;
}>;

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "serviceId",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "SharedCarouselWrapper_item",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "browseUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "size",
          "value": "large"
        }
      ],
      "kind": "ScalarField",
      "name": "firstPhotoWebPath",
      "storageKey": "firstPhotoWebPath(size:\"large\")"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "categoryCode",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "contemporaryTrackingString",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "categoryPath",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "localizedPdpUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Seller",
      "kind": "LinkedField",
      "name": "seller",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "Item",
  "abstractKey": null
};
})();

(node as any).hash = "c8c11fe88fb4a523f95650fb1e506b67";

export default node;
