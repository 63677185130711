/**
 * @generated SignedSource<<6407b8804cf3bbf04041fbfdcc200427>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SbRespAppliedFilters_user$data = {
  readonly " $fragmentSpreads": FragmentRefs<"useMeasurementUnit_user">;
  readonly " $fragmentType": "SbRespAppliedFilters_user";
};
export type SbRespAppliedFilters_user$key = {
  readonly " $data"?: SbRespAppliedFilters_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"SbRespAppliedFilters_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SbRespAppliedFilters_user",
  "selections": [
    {
      "kind": "InlineDataFragmentSpread",
      "name": "useMeasurementUnit_user",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "UserPreferences",
          "kind": "LinkedField",
          "name": "preferences",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "measurementUnit",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "args": null,
      "argumentDefinitions": []
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "b1df331eb05f1806d7eef22f5659a81d";

export default node;
