/**
 * @generated SignedSource<<69fc50f76a9f107ac870d38dd0e862dc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SbRespRefineMenu_user$data = {
  readonly " $fragmentSpreads": FragmentRefs<"SbSharedRefineMenuDisplayPrefsSelect_user" | "SbSharedRefineMenuFilters_user">;
  readonly " $fragmentType": "SbRespRefineMenu_user";
};
export type SbRespRefineMenu_user$key = {
  readonly " $data"?: SbRespRefineMenu_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"SbRespRefineMenu_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SbRespRefineMenu_user",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbSharedRefineMenuFilters_user"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbSharedRefineMenuDisplayPrefsSelect_user"
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "5836778420670908e71060228469c49b";

export default node;
