import { lazy, Suspense } from 'react';

const SbSharedPersonalizedFilterToastWrapper = lazy(() =>
    import(
        /* webpackChunkName: "SbSharedPersonalizedFilterToastWrapper" */ './SbSharedPersonalizedFilterToastWrapper'
    )
);

export default function SbSharedPersonalizedFilterToastLazy(props) {
    return (
        <Suspense fallback="">
            <SbSharedPersonalizedFilterToastWrapper {...props} />
        </Suspense>
    );
}
